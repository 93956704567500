import React from "react";
import "../../../views/Style.css";
import {Button, Col, Container, Dropdown, Modal, Nav, Row,} from "react-bootstrap";
import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {properties} from "../../../properties.js";
import {FaUserEdit} from "react-icons/fa";
import PostForm from "./Post";
import Comments from "./Comments"
import {NotificationManager} from "react-notifications";
export class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            criteria: {page: 1, size: 10},
            showPostForm: false,
            showPhotos: false,
            showComments: false,
            focusedInput: null,
            showDeleteModal: false,
            showApproveModal: false
        };
        this.hidePostForm = this.hidePostForm.bind(this);
        this.hideComments = this.hideComments.bind(this);

    }


    componentDidMount() {
        this.loadPosts(this.state.criteria)

    }

    loadPagination(criteria) {

        let paginationViews = undefined;

        if (criteria?.totalPages && criteria.totalPages > 1) {

            const subViews = [];
            let size = criteria.size;
            const count = criteria.totalPages > 10 ?
                (window.innerWidth < 700 ? (criteria.page + 4) : (criteria.page + 9))
                : criteria.totalPages;

            for (let i = criteria.page; i <= count; i++) {

                let page = i;
                console.log('page-------------', page);

                if (page === criteria.page) {
                    subViews.push(
                        <li className="page-item active">
                            <a className="page-link" href="#" onClick={() => {
                                this.loadPosts({page, size})
                            }}>
                                {page}<span className="sr-only">(current)</span>
                            </a>.
                        </li>
                    )
                } else {
                    subViews.push(<li className="page-item">
                            <a className="page-link" href="#" onClick={() => {
                                this.loadPosts({page, size})
                            }}>
                                {page}<span className="sr-only">(current)</span>
                            </a>
                        </li>
                    )
                }


            }

            paginationViews = (
                <div key={'key-admin-pagination-halalla-100'}
                     style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                    <nav aria-label="...">
                        <ul className="pagination">
                            <li className={'page-item ' + (criteria.page > 1 ? '' : 'disabled')}>
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => {
                                        if (criteria.page > 1) {
                                            this.loadPosts({page: criteria.page - 1, size});
                                        }
                                    }}
                                >
                                    Previous
                                < /a>
                            </li>
                            {
                                subViews
                            }
                            <li className={'page-item ' + (criteria.page < criteria.totalPages ? '' : 'disabled')}>
                                <a className="page-link" href="#" onClick={() => {
                                    if (criteria.page > 1) {
                                        this.loadPosts({page: criteria.page + 1, size});
                                    }
                                }}>
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>)


            console.log(paginationViews);
        }


        this.setState({paginationViews});

    }

    async loadPosts(criteria) {
        try {
            this.setState({loading: true});
            criteria = await service.loadPosts(criteria);
            const posts = criteria.posts;
            console.log("Post---------", posts);
            this.setState({criteria, posts});
            this.loadPagination(criteria);
        } catch (err) {
            console.log(err);
        }
        this.setState({loading: false});
    }



    showEdit(i) {
        const post = this.state.posts[i];
        this.setState({
            showPostForm: true,
            selectedPostId: post._id
        });
    }

    showComments(i) {
        const post = this.state.posts[i];
        this.setState({
            showComments: true,
            selectedPost: post
        });
    }

    showApproval(i) {
        const post = this.state.posts[i];
        console.log('Selected Post for Approval-----------------:', post);
        this.setState({
            showApproveModal: true,
            selectedPostId: post._id
        });
    }


    showDelete(i) {
        const post = this.state.posts[i];
        this.setState({
            showDeleteModal: true,
            selectedPostId: post._id
        });
    }


    async hidePostForm() {
        this.setState({
            loading: false, showPostForm: false, selectPostId: undefined
        });
        this.loadPosts();
    }


    async hideComments() {
        this.setState({
            loading: false, showComments: false, selectedPost: undefined
        });
    }

    renderPostFormScreen() {
        return <PostForm id={this.state.selectedPostId} hide={this.hidePostForm}/>;
    }

   /* renderPhotosScreen() {
        return <Photos blog={this.state.selectedBlog} hide={this.hidePhotos}/>;
    } */

    renderCommentsScreen() {
        return <Comments post={this.state.selectedPost} hide={this.hideComments}/>;
    }

    handleInputFocus = (inputName) => {
        this.setState({focusedInput: inputName});
    };

    handleInputBlur = () => {
        this.setState({focusedInput: null});
    };


    renderDeleteModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showDeleteModal}
                onHide={() => {}}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <i style={{marginRight: 10}} class="fa fa-trash"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do you want to delete this post</span>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => this.setState({
                            showDeleteModal: false,
                        })}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={async () => {
                            try {
                                await service.deletePost({_id: this.state.selectedPostId});
                                this.loadPosts(this.state.criteria)
                            } catch (err) {
                                console.log(err)
                            }
                            this.setState({
                                showDeleteModal: false, selectedPostId: undefined
                            })
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        );
    }

    renderApproveModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showApproveModal}
                onHide={() => {}}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <img
                            src={require("../../../assets/img/chef.png")}
                            alt="Delete Icon"
                            style={{width: '100', marginBottom: 22}}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do you want to approve this Post</span>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => this.setState({
                            showApproveModal: false,
                        })}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={async () => {
                            try {
                                this.setState({ loader: true });
                                const postId = this.state.selectedPostId;
                                await service.approvePost({ _id: postId, approved: true });
                                this.loadPosts(this.state.criteria);
                                NotificationManager.success('Post Approved Successfully', 'Saved');
                                this.setState({ loading: false, showApproveModal: false });
                            } catch (err) {
                                console.log("Error----------", err);
                                this.setState({ loading: false });
                            }
                        }}
                    >
                        Approve
                    </Button>
                </div>
            </Modal>
        );
    }



    render() {

        if (this.state.showPostForm) {
            return this.renderPostFormScreen();
        } else if (this.state.showPhotos) {
            return this.renderPhotosScreen();
        } else if (this.state.showComments) {
            return this.renderCommentsScreen();
        } else {
            var context = this;
            return <>
                {this.renderDeleteModal()}
                {this.renderApproveModal()}

                <Container fluid>
                    <Row
                        style={{
                            display: "flex", flexDirection: "row", marginBottom: 20,
                        }}
                    >
                        <Col md="6">
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <label className="main-heading">
                                    Posts
                                </label>
                            </div>
                        </Col>
                        <Col>
                            <div
                                className="search-container"
                                style={{
                                    border: this.state.focusedInput === 'search' ? '2px solid #b82e3a' : '',
                                    borderRadius: 6
                                }}
                            >
                                <input
                                    autoComplete="off"
                                    type='search'
                                    name="search"
                                    value={this.state.search}
                                    placeholder="Search Post"
                                    onChange={(e) => {
                                        const search = e.target.value;
                                        const criteria = this.state.criteria;
                                        criteria.search = search;
                                        this.setState({criteria, search});
                                        this.loadPosts(criteria);
                                    }}
                                    className="search-input"
                                    onFocus={() => this.handleInputFocus('search')}
                                    onBlur={this.handleInputBlur}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            alert(1)
                                        }
                                    }}
                                />
                                <i style={{
                                    color: this.state.focusedInput === 'search' ? '#b82e3a' : '',
                                }}
                                   className='fa fa-search'
                                   id="search-icon"
                                ></i>

                            </div>
                        </Col>
                        <div className="d-none d-md-block"> {/* Applies only to screens medium-sized and larger */}
                            <Col>
                                <div style={{display: "flex", justifyContent: "left"}}>
                                    <button
                                        className="add-btn"
                                        type="submit"
                                        onClick={() => {
                                            this.setState({
                                                showPostForm: true,
                                                active: true
                                            });
                                        }}
                                    >
                                        Add Post
                                        <i className="add-btn-icon fa fa-plus-circle"></i>{" "}
                                    </button>
                                </div>
                            </Col>
                        </div>

                        {/* Conditionally render the button only in mobile view */}
                        <div className="d-md-none"> {/* Applies only to screens smaller than md (medium) */}
                            <Col>
                                <div style={{display: "flex", justifyContent: "left"}}>
                                    <button
                                        className="add-btn-mbl"
                                        type="submit"
                                        onClick={() => {
                                            this.setState({
                                                showPost: true,
                                                active: true
                                            });
                                        }}
                                    >
                                        <i className="add-btn-icon-mbl fa fa-plus-circle"></i>{" "}
                                    </button>
                                </div>
                            </Col>
                        </div>
                    </Row>

                    <Row>
                        <Col md="12">
                            <table className="custom-table">
                                <thead>
                                <tr>
                                    <th className="centered-heading">S.No</th>
                                    <th className="centered-heading">Post Title</th>
                                    <th className="centered-heading">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.posts &&
                                    this.state.posts.map((item, index) => {
                                        console.log("---LIST------", item);
                                        return (
                                            <tr key={'key-halallas-table-' + index}>
                                                <td className="centered-data">{index + 1}</td>
                                                <td className="centered-data">{item.title}</td>
                                                <td className="centered-data">
                                                    <Dropdown style={{
                                                        justifyContent:'center',
                                                        display:'flex'
                                                    }}>
                                                        <Dropdown.Toggle
                                                            as={Nav.Link}
                                                            data-toggle="dropdown"
                                                            id="dropdown-67443507"
                                                            variant="default"
                                                            className="centered-data"
                                                            style={{
                                                                color: "#000",
                                                        }}
                                                        >
                                                            <label>
                                                                {properties.commonLabel.tableHeaderAction}
                                                            </label>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={context.showEdit.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Edit Form
                                                            </Dropdown.Item>

                                                            <Dropdown.Item
                                                                onClick={context.showComments.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Comments
                                                            </Dropdown.Item>

                                                            { !item.approved &&
                                                                <Dropdown.Item
                                                                    onClick={context.showApproval.bind(context, index)}
                                                                >
                                                                    <FaUserEdit
                                                                        size={20}
                                                                        style={{
                                                                            marginRight: 5,
                                                                            marginBottom: 5,
                                                                        }}
                                                                    />
                                                                    Approve
                                                                </Dropdown.Item>
                                                            }
                                                            <Dropdown.Item
                                                                onClick={context.showDelete.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Delete
                                                            </Dropdown.Item>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {this.state.paginationViews}


                        </Col>
                    </Row>
                </Container>
            </>
        }
    }
}

export default Index;
