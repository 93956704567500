import React from "react";
import "../../../views/Style.css";
import {Button, Card, Col, Container, Form, InputGroup, Modal, Row,} from "react-bootstrap";

import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {properties} from "../../../properties.js";
import {SLoader} from "../../../components/CommonComponents";
import Editor from "./../Editor";
import {NotificationManager,} from "react-notifications";
import {FaBuilding} from "react-icons/fa";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import {loadBlog, saveBlog} from "../../../services/Service.js";

const timings = [
    {value: "00:00:00", label: "12:00 AM"},
    {value: "00:30:00", label: "12:30 AM"},
    {value: "01:00:00", label: "1:00 AM"},
    {value: "01:30:00", label: "1:30 AM"},
    {value: "02:00:00", label: "2:00 AM"},
    {value: "02:30:00", label: "2:30 AM"},
    {value: "03:00:00", label: "3:00 AM"},
    {value: "03:30:00", label: "3:30 AM"},
    {value: "04:00:00", label: "4:00 AM"},
    {value: "04:30:00", label: "4:30 AM"},
    {value: "05:00:00", label: "5:00 AM"},
    {value: "05:30:00", label: "5:30 AM"},
    {value: "06:00:00", label: "6:00 AM"},
    {value: "06:30:00", label: "6:30 AM"},
    {value: "07:00:00", label: "7:00 AM"},
    {value: "07:30:00", label: "7:30 AM"},
    {value: "08:00:00", label: "8:00 AM"},
    {value: "08:30:00", label: "8:30 AM"},
    {value: "09:00:00", label: "9:00 AM"},
    {value: "09:30:00", label: "9:30 AM"},
    {value: "10:00:00", label: "10:00 AM"},
    {value: "10:30:00", label: "10:30 AM"},
    {value: "11:00:00", label: "11:00 AM"},
    {value: "11:30:00", label: "11:30 AM"},
    {value: "12:00:00", label: "12:00 PM"},
    {value: "12:30:00", label: "12:30 PM"},
    {value: "13:00:00", label: "01:00 PM"},
    {value: "13:30:00", label: "01:30 PM"},
    {value: "14:00:00", label: "02:00 PM"},
    {value: "14:30:00", label: "02:30 PM"},
    {value: "15:00:00", label: "03:00 PM"},
    {value: "15:30:00", label: "03:30 PM"},
    {value: "16:00:00", label: "04:00 PM"},
    {value: "16:30:00", label: "04:30 PM"},
    {value: "17:00:00", label: "05:00 PM"},
    {value: "17:30:00", label: "05:30 PM"},
    {value: "18:00:00", label: "06:00 PM"},
    {value: "18:30:00", label: "06:30 PM"},
    {value: "19:00:00", label: "07:00 PM"},
    {value: "19:30:00", label: "07:30 PM"},
    {value: "20:00:00", label: "08:00 PM"},
    {value: "20:30:00", label: "08:30 PM"},
    {value: "21:00:00", label: "09:00 PM"},
    {value: "21:30:00", label: "09:30 PM"},
    {value: "22:00:00", label: "10:00 PM"},
    {value: "22:30:00", label: "10:30 PM"},
    {value: "23:00:00", label: "11:00 PM"},
    {value: "23:30:00", label: "11:30 PM"},
];


export class blog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blogId: props.id,
            loading: false,
            loader: false,
            showDeleteModal: false,
            showApproveModal: false,
            active:true,
        };
    }


    async loadBlog() {
        try {
            this.setState({loading: true})
            const blogForm = await service.loadBlog(this.state.blogId);
            console.log("blogForm Edit---------", blogForm);
            this.setState({...blogForm});
        } catch (err) {
            console.log(err)
        }
        this.setState({loading: false})
    }

    async saveBlog() {
        try {
            this.setState({ loader: true });
            const blog = {
                _id : this.state.blogId,
                title : this.state.title,
                description : this.state.description,
                active : this.state.active,
            };
            await service.saveBlog(this.state.blogId,blog);
            console.log("Save Blog---------",blog);
            NotificationManager.success('Blog Saved Successfully', 'Saved');
            this.setState({ loader: false });
            this.props.hide();
        } catch (err) {
            console.log("Error----------",err);
        }
        this.setState({ loading: false });
    }


    componentDidMount() {
        this.loadBlog();
    }

    renderDeleteModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showDeleteModal}
                onHide={() => {
                }}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <i style={{marginRight: 10}} class="fa fa-trash"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do you want to delete this chef form</span>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => this.setState({
                            showDeleteModal: false,
                        })}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => {
                            this.loadHalallas();
                            this.setState({
                                showDeleteModal: false
                            })
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        );
    }


    render() {
        if (this.state.loading) {
            return <SLoader/>;
        } else {
            var context = this;
            return (
                <>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Header>
                                        <div className="form-header">
                                            <span>Blog Form</span>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md="10" className="payment">

                                                <Form.Group>
                                                    <label>Title</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <FaBuilding size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="input"
                                                            value={this.state.title}
                                                            onChange={(e) => {
                                                                this.setState({ title: e.target.value });
                                                            }}
                                                        >
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col md="10" style={{marginTop: '15px'}}>
                                                <Form.Group>
                                                    <label>{properties.product.description}</label>
                                                    <Editor
                                                        value={this.state.description}
                                                        onChange={val => {
                                                            this.setState({description: val});
                                                        }}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                marginTop: 20,
                                            }}
                                        >
                                            <Checkbox
                                                onChange={(active, event) => {
                                                    this.setState({active: active});
                                                }}
                                                checked={this.state.active}
                                                value={this.state.active}
                                                icon={
                                                    <div
                                                        style={{
                                                            backgroundColor: "#29427d",
                                                        }}
                                                    >
                                                        <Icon.FiCheck color="#b82e3a" size={20}/>
                                                    </div>
                                                }
                                                borderColor="gray"
                                                // borderWidth={0}
                                                borderRadius={20}
                                                style={{overflow: "hidden"}}
                                                size={20}
                                                label="Active"
                                            />
                                        </div>

                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() =>
                                                    this.props.hide()
                                                }
                                            >
                                                Back
                                            </button>


                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() => {
                                                    this.saveBlog();
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>

                                        <div className="clearfix"></div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    {this.renderDeleteModal()}
                </>
            );
        }
    }
}

export default blog;
