import React from "react";
import "react-image-crop/src/ReactCrop.scss";

// react-bootstrap components
import {Card, Col, Container, Row,} from "react-bootstrap";

import * as service from "../../../services/Service.js";
import "react-image-crop/dist/ReactCrop.css";

const avatarUrl = require("../../../assets/img/123.jpeg");

export class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            request: props.request,
            loading: false,
        };
    }

    async loadHalallaGallery() {
        try {
            this.setState({loading: true})
            const gallery = this.state.request.gallery;
            this.setState({gallery, orgGallery: gallery});

            const images = [];

            this.setState({images});

            for (let i = 0; i < gallery.length; i++) {
                let path = gallery[i];
                const base64 = await service.loadImageBase64(path);
                // console.log(base64);


                images.push({
                    index: i,
                    path,
                    image: (<div><img src={base64}></img></div>)
                });

            }
            this.setState({images});

            console.log(images);


        } catch (err) {
            console.log(err)
        }
        this.setState({loading: false})

    }

    componentDidMount() {
        this.loadHalallaGallery();
    }

    renderMain() {
        return (
            <Card className="strpied-tabled-with-hover" style={{padding: 20}}>
                <Card.Header style={{display: "flex"}}>
                    <div
                        style={{display: "flex", width: "100%", justifyContent: "right"}}
                    >
                        <button
                            className="saveProductBtn"
                            onClick={() => {
                                this.props.hide();
                            }}
                        >
                            Back
                        </button>

                    </div>
                </Card.Header>
                <Card.Body>
                    {this.state.images
                        && this.state.images.map((item, index) => {
                            return item.image;
                        })}
                </Card.Body>
            </Card>
        )
            ;
    }


    render() {
        return (
            <>
                <Container fluid style={{padding: 10}}>
                    <Row>
                        <Col md="12">
                            <p style={{fontSize: 20, marginLeft: 5}}>Gallery
                                - {this.state.request.halalla.title}</p>
                            {this.renderMain()}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Gallery;
