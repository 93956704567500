/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { properties } from "./properties.js";
import Halallas from "./views/admin/Halallas";
import Customer from "./views/admin/Customer";
import ResetPassword from "./views/admin/ResetPassword";
import HalallaApproval from "./views/admin/HalallaForms";
import ChefApproval from "./views/admin/ChefForms";
import HalallaImageRequest from "./views/admin/HalallaImageRequest";
import Chefs from "./views/admin/Chefs";
import Blogs from "./views/admin/Blogs";
import Posts from "./views/admin/Posts";

const Error = () => <>Error</>;

const dashboardRoutes = [
  {
    path: "/halalla",
    name: properties.common.halalla,
    icon: "nc-icon nc-bank",
    children: [
      {
        path: "/halallas",
        name: properties.common.halallas,
        icon: "nc-icon nc-bank",
        component: Halallas,
        layout: "/admin",
      },
      {
        path: "/halalla-approval",
        name: "Approval",
        icon: "nc-icon nc-ruler-pencil",
        component: HalallaApproval,
        layout: "/admin",
      },
      {
        path: "/halalla-image-approval",
        name: "Image Req",
        icon: "nc-icon nc-ruler-pencil",
        component: HalallaImageRequest,
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/customer",
    name: properties.common.customer,
    icon: "nc-icon nc-badge",
    component: Customer,
    layout: "/admin",
  },
  {
    path: "/chefs",
    name: "Chef",
    icon: "nc-icon nc-circle-09",
    component: Chefs,
    layout: "/admin",
  },
  //{
  //  path: "/chef",
  //  name: "Chef",
  //  children: [
  //    {
  //     path: "/chefs",
  //      name: "Chefs",
  //      icon: "nc-icon nc-bank",
  //      component: Chefs,
  //     layout: "/admin",
  //   },
  //   {
  //    path: "/chef-approval",
  //   name: "Approval",
  //   icon: "nc-icon nc-ruler-pencil",
  //   component: ChefApproval,
  //   layout: "/admin",
  //},
  //],

  // icon: "nc-icon nc-bank",
  //  layout: "/admin",
  //},
  {
    path: "/blogs",
    name: "Blogs",
    icon: "nc-icon nc-single-copy-04",
    component: Blogs,
    layout: "/admin",
  },
  {
    path: "/posts",
    name: "Posts",
    icon: "nc-icon nc-notes",
    component: Posts,
    layout: "/admin",
  },
  {
    path: "/resetpassword",
    name: properties.common.resetPassword,
    icon: "nc-icon nc-lock-circle-open",
    component: ResetPassword,
    layout: "/admin",
  },
];

const dashboardRoutes1 = [
  {
    path: "/halalla",
    name: properties.common.halallas,
    icon: "nc-icon nc-bank",
    component: Halallas,
    layout: "/admin",
  },
  {
    path: "/approval",
    name: "H Approval",
    icon: "nc-icon nc-ruler-pencil",
    component: HalallaApproval,
    layout: "/admin",
  },
  {
    path: "/customer",
    name: properties.common.customer,
    icon: "nc-icon nc-single-02",
    component: Customer,
    layout: "/admin",
  },
  {
    path: "/chefs",
    name: "Chefs",
    icon: "fas fa-chalkboard-teacher",
    component: Chefs,
    layout: "/admin",
  },
  {
    path: "/chef-approval",
    name: "C Approval",
    icon: "fab fa-wpforms",
    component: ChefApproval,
    layout: "/admin",
  },
  {
    path: "/resetpassword",
    name: properties.common.resetPassword,
    icon: "nc-icon nc-lock-circle-open",
    component: ResetPassword,
    layout: "/admin",
  },
];

export default dashboardRoutes;
