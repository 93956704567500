import React, {useEffect} from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import Login from "views/Login.js";
import * as service from "../services/Service.js";
import {getLogin} from "../services/Service.js";
import {SLoader} from "../components/CommonComponents";
import {NotificationContainer} from "react-notifications";

import routes from "routes.js";


import sidebarImage from "../assets/img/4.jpg";

function Admin() {

    const [loggedIn, setLoggedIn] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [role, setRole] = React.useState('');
    const [image, setImage] = React.useState(sidebarImage);
    const [color, setColor] = React.useState("black");
    const [hasImage, setHasImage] = React.useState(true);
    const location = useLocation();
    const mainPanel = React.useRef(null);

    const hideLoggedIn = () => {
        setLoggedIn(true);
    };

    const isLoggedIn = async () => {
        const loginInfo = await service.getLogin();
        const isLoggedIn = await service.isLoggedIn();
        setLoggedIn(isLoggedIn);
        setRole(loginInfo?.role ? loginInfo.role : '');
        setLoading(false);
    }


    useEffect(() => {
        isLoggedIn();

        getLogin().then(res => console.log(res));

    }, []);


    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            console.log('Menu', role, key, prop)

            if (role === 'SuperAdmin') {

                if (prop.children) {
                    return prop.children.map((item, index) => {
                        console.log('Child ', index, item)
                        return (
                            <Route
                                exact
                                path={item.path}
                                render={(props) => <item.component {...props} />}
                                key={'admin-rout-key-' + key + '-c-' + index}
                            />
                        );
                    })
                } else {
                    return (
                        <Route
                            exact
                            path={prop.path}
                            render={(props) => <prop.component {...props} />}
                            key={'admin-rout-key-' + key}
                        />
                    );
                }

            }
        });
    };


    if (loading) {
        return <SLoader/>;
    } else if (!loggedIn) {
        return <Login hideLogged In={hideLoggedIn}/>;
    } else if (loggedIn) {
        console.log('render Admin')
        return (
            <>
                <div className="wrapper">
                    <Sidebar
                        color={color}
                        image={hasImage ? image : ""}
                        routes={routes}
                    />
                    <div className="main-panel" ref={mainPanel}>
                        <AdminNavbar/>
                        <div className="content">
                            <NotificationContainer/>
                            <Switch>{getRoutes(routes)}</Switch>
                        </div>
                        <Footer/>
                    </div>
                </div>
                <FixedPlugin
                    hasImage={hasImage}
                    setHasImage={() => setHasImage(!hasImage)}
                    color={color}
                    setColor={(color) => setColor(color)}
                    image={image}
                    setImage={(image) => setImage(image)}
                />
            </>
        );
    } else {
        return null
    }
}

export default Admin;
