import React from "react";
import "../../../views/Style.css";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Modal,
  Nav,
  Row,
} from "react-bootstrap";
import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {
  MdDeleteSweep,
  MdEditDocument,
  MdMyLocation,
  MdPhoto,
  MdPhotoLibrary,
  MdSchedule,
} from "react-icons/md";
import { properties } from "../../../properties.js";
import { SLoader } from "../../../components/CommonComponents";
import Photos from "./photos";
import Gallery from "./gallery";
import ChefScreen from "./chef";
import LocationScreen from "./location";
import ScheduleScreen from "./schedule";
import { deleteChef, loadHalallas } from "../../../services/Service.js";

export class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      criteria: { page: 1, size: 10 },
      showChef: false,
      showLocation: false,
      showGallery: false,
      showPhotos: false,
      showSchedule: false,
      halallas: [],
      focusedInput: null,
    };
    this.hideGallery = this.hideGallery.bind(this);
    this.hideChef = this.hideChef.bind(this);
    this.hideLocation = this.hideLocation.bind(this);
    this.hideSchedule = this.hideSchedule.bind(this);
    this.hidePhotos = this.hidePhotos.bind(this);
  }

  componentDidMount() {
    this.loadChefs(this.state.criteria);
  }

  loadPagination(criteria) {
    let paginationViews = undefined;

    if (criteria?.totalPages && criteria.totalPages > 1) {
      const subViews = [];
      let size = criteria.size;
      const count =
        criteria.totalPages > 10 ? criteria.page + 9 : criteria.totalPages;

      for (let i = criteria.page; i <= count; i++) {
        let page = i;
        console.log("page-------------", page);

        if (page === criteria.page) {
          subViews.push(
            <li className="page-item active">
              <a
                className="page-link"
                href="#"
                onClick={() => {
                  this.loadChefs({ page, size, search: criteria.search });
                }}
              >
                {page}
                <span className="sr-only">(current)</span>
              </a>
              .
            </li>,
          );
        } else {
          subViews.push(
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                onClick={() => {
                  this.loadChefs({ page, size, search: criteria.search });
                }}
              >
                {page}
                <span className="sr-only">(current)</span>
              </a>
            </li>,
          );
        }
      }

      paginationViews = (
        <div
          key={"key-admin-pagination-halalla-100"}
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <nav aria-label="...">
            <ul className="pagination">
              <li
                className={"page-item " + (criteria.page > 1 ? "" : "disabled")}
              >
                <a
                  className="page-link"
                  href="#"
                  onClick={() => {
                    if (criteria.page > 1) {
                      this.loadChefs({
                        page: criteria.page - 1,
                        size,
                        search: criteria.search,
                      });
                    }
                  }}
                >
                  Previous
                </a>
              </li>
              {subViews}
              <li
                className={
                  "page-item " +
                  (criteria.page < criteria.totalPages ? "" : "disabled")
                }
              >
                <a
                  className="page-link"
                  href="#"
                  onClick={() => {
                    if (criteria.page > 1) {
                      this.loadChefs({
                        page: criteria.page + 1,
                        size,
                        search: criteria.search,
                      });
                    }
                  }}
                >
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div>
      );

      console.log(paginationViews);
    }

    this.setState({ paginationViews });
  }

  async loadChefs(criteria) {
    try {
      this.setState({ loading: true });
      criteria = await service.loadChefs(criteria);
      const chefs = criteria.chefs; // Extract the array from the response
      console.log("chefssss------", chefs);
      console.log(criteria);
      this.setState({ criteria, chefs });
      this.loadPagination(criteria);
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  }

  showEdit(i) {
    const chef = this.state.chefs[i];
    this.setState({
      showChef: true,
      selectedChefId: chef._id,
    });
  }

  showLocation(i) {
    const location = this.state.chefs[i];
    console.log("---Edit location---", location);
    this.setState({
      showLocation: true,
      selectedChefId: location._id,
      selectedChef: location,
    });
  }

  showSchedule(i) {
    const schedule = this.state.chefs[i];
    console.log("---Edit Schedule---", schedule);
    this.setState({
      showSchedule: true,
      selectedChefId: schedule._id,
      selectedChef: schedule,
    });
  }

  showGallery(i) {
    const gallery = this.state.chefs[i];
    this.setState({
      showGallery: true,
      selectedChef: gallery,
    });
  }

  showPhotos(i) {
    const photo = this.state.chefs[i];
    this.setState({
      showPhotos: true,
      selectedChef: photo,
    });
  }

  showDelete(i) {
    const chef = this.state.chefs[i];
    this.setState({
      showDeleteModal: true,
      selectedChefId: chef._id,
    });
  }

  async hideGallery() {
    this.setState({
      loading: false,
      showGallery: false,
      selectedChef: undefined,
    });
  }

  async hidePhotos() {
    this.setState({
      loading: false,
      showPhotos: false,
      selectedChef: undefined,
    });
  }

  renderGalleryScreen() {
    return <Gallery chef={this.state.selectedChef} hide={this.hideGallery} />;
  }

  renderPhotosScreen() {
    return <Photos chef={this.state.selectedChef} hide={this.hidePhotos} />;
  }

  async hideChef() {
    this.setState({
      loading: false,
      showChef: false,
      selectedChefId: undefined,
    });
    this.loadChefs();
  }

  renderChefScreen() {
    return <ChefScreen id={this.state.selectedChefId} hide={this.hideChef} />;
  }

  async hideLocation() {
    try {
      this.setState({ loading: true });
    } catch (e) {
      console.log(e);
    }

    this.setState({
      loading: false,
      showLocation: false,
      selectedHalallaId: undefined,
    });
  }

  renderLocationScreen() {
    return (
      <LocationScreen
        id={this.state.selectedChefId}
        chef={this.state.selectedChef}
        hide={this.hideLocation}
      />
    );
  }

  async hideSchedule() {
    this.setState({
      loading: false,
      showSchedule: false,
      selectedChefId: undefined,
    });
  }

  renderScheduleScreen() {
    return (
      <ScheduleScreen
        id={this.state.selectedChefId}
        chef={this.state.selectedChef}
        hide={this.hideSchedule}
      />
    );
  }

  handleInputFocus = (inputName) => {
    this.setState({ focusedInput: inputName });
  };

  handleInputBlur = () => {
    this.setState({ focusedInput: null });
  };

  renderDeleteModal() {
    return (
      <Modal
        className="modal-big modal-primary"
        show={this.state.showDeleteModal}
        onHide={() => {}}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i style={{ marginRight: 10 }} class="fa fa-trash"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
          <span>Do you want to delete this chef</span>
        </Modal.Body>
        <div
          className="modal-footer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="btn-delete"
            type="button"
            variant="link"
            onClick={async () => {
              this.setState({
                showDeleteModal: false,
                title: undefined,
                selectedHalallas: undefined,
              });
            }}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button
            className="btn-delete"
            type="button"
            variant="link"
            onClick={async () => {
              try {
                await service.deleteChef({ _id: this.state.selectedChefId });
                this.loadChefs(this.state.criteria);
              } catch (err) {
                console.log(err);
              }
              this.setState({
                showDeleteModal: false,
                selectedChefId: undefined,
              });
            }}
          >
            Delete
          </Button>
        </div>
      </Modal>
    );
  }

  render() {
    if (this.state.showChef) {
      return this.renderChefScreen();
    } else if (this.state.showLocation) {
      return this.renderLocationScreen();
    } else if (this.state.showSchedule) {
      return this.renderScheduleScreen();
    } else if (this.state.showGallery) {
      return this.renderGalleryScreen();
    } else if (this.state.showPhotos) {
      return this.renderPhotosScreen();
    } else {
      var context = this;
      return (
        <>
          {this.renderDeleteModal()}
          <Container fluid>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
              }}
            >
              <Col md="6">
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <label className="main-heading">Chefs</label>
                </div>
              </Col>
              <Col>
                <div
                  className="search-container"
                  style={{
                    border:
                      this.state.focusedInput === "search"
                        ? "2px solid #b82e3a"
                        : "",
                    borderRadius: 6,
                  }}
                >
                  <input
                    autoComplete="off"
                    type="search"
                    name="search"
                    value={this.state.search}
                    placeholder="Search Chef"
                    onChange={(e) => {
                      const search = e.target.value;
                      const criteria = this.state.criteria;
                      criteria.search = search;
                      this.setState({ criteria, search });
                      this.loadChefs(criteria);
                    }}
                    className="search-input"
                    onFocus={() => this.handleInputFocus("search")}
                    onBlur={this.handleInputBlur}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        alert(1);
                      }
                    }}
                  />
                  <i
                    style={{
                      color:
                        this.state.focusedInput === "search" ? "#b82e3a" : "",
                    }}
                    className="fa fa-search"
                    id="search-icon"
                  ></i>
                </div>
              </Col>
              {/* Applies only to screens medium-sized and larger */}
              {/*<div className="d-none d-md-block">
                        <Col>
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <button
                                    className="add-btn"
                                    type="submit"
                                    onClick={() => {
                                        this.setState({
                                            showChef: true,
                                            active: true
                                        });
                                    }}
                                >
                                    Add Halalla
                                    <i className="add-btn-icon fa fa-plus-circle"></i>{" "}
                                </button>
                            </div>
                        </Col>
                    </div>*/}

              {/* Conditionally render the button only in mobile view */}
              {/* <div className="d-md-none">
                        <Col>
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <button
                                    className="add-btn-mbl"
                                    type="submit"
                                    onClick={() => {
                                        this.setState({
                                            showChef: true,
                                            active: true
                                        });
                                    }}
                                >
                                    <i className="add-btn-icon-mbl fa fa-plus-circle"></i>{" "}
                                </button>
                            </div>
                        </Col>
                    </div>*/}
            </Row>
            <Row>
              <Col md="12">
                {this.state.loading && <SLoader />}
                {!this.state.loading && (
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th className="centered-heading">S.No</th>
                        <th className="centered-heading">Chef Name</th>
                        <th className="centered-heading">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!this.state.loading &&
                        this.state.chefs &&
                        this.state.chefs.map((item, index) => {
                          console.log("---LIST------", item);
                          return (
                            <tr key={"key-halallas-table-" + index}>
                              <td className="centered-data">{index + 1}</td>
                              <td className="centered-data">
                                {item.firstName} {item.lastName}
                              </td>
                              <td className="centered-data">
                                <Dropdown
                                  style={{
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <Dropdown.Toggle
                                    as={Nav.Link}
                                    data-toggle="dropdown"
                                    id="dropdown-67443507"
                                    variant="default"
                                    className="m-0"
                                    style={{ color: "#000" }}
                                  >
                                    <label>
                                      {properties.commonLabel.tableHeaderAction}
                                    </label>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={context.showEdit.bind(
                                        context,
                                        index,
                                      )}
                                    >
                                      <MdEditDocument
                                        size={20}
                                        style={{
                                          marginRight: 5,
                                          marginBottom: 5,
                                        }}
                                      />
                                      Modify
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={context.showLocation.bind(
                                        context,
                                        index,
                                      )}
                                    >
                                      <MdMyLocation
                                        size={20}
                                        style={{
                                          marginRight: 5,
                                          marginBottom: 5,
                                        }}
                                      />
                                      Location
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={context.showSchedule.bind(
                                        context,
                                        index,
                                      )}
                                    >
                                      <MdSchedule
                                        size={20}
                                        style={{
                                          marginRight: 5,
                                          marginBottom: 5,
                                        }}
                                      />
                                      Schedule
                                    </Dropdown.Item>

                                    {/*<Dropdown.Item
                                                        onClick={context.showGallery.bind(context, index)}
                                                    >
                                                        <MdPhotoLibrary
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        {properties.commonLabel.actionGallery}
                                                    </Dropdown.Item>*/}
                                    <Dropdown.Item
                                      onClick={context.showPhotos.bind(
                                        context,
                                        index,
                                      )}
                                    >
                                      <MdPhoto
                                        size={20}
                                        style={{
                                          marginRight: 5,
                                          marginBottom: 5,
                                        }}
                                      />
                                      Photos
                                    </Dropdown.Item>
                                    {/*<Dropdown.Item
                                      onClick={context.showDelete.bind(
                                        context,
                                        index,
                                      )}
                                    >
                                      <MdDeleteSweep
                                        size={20}
                                        style={{
                                          marginRight: 5,
                                          marginBottom: 5,
                                        }}
                                      />
                                      Delete
                                    </Dropdown.Item>*/}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th className="centered-heading"></th>
                        <th className="centered-heading">Total Records</th>
                        <th className="centered-heading">
                          {this.state.criteria?.totalRecords}
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                )}

                {!this.state.loading && this.state.paginationViews}
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}

export default Index;
