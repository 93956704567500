import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import * as service from "../../../services/Service.js";
import { properties } from "../../../properties.js";
import "react-image-crop/src/ReactCrop.scss";
export class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chef: props.chef,
      loading: false,
      image: null,
      crop: {
        unit: "px",
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      },
    };
  }

  async deleteImage(imagePath) {
    try {
      this.setState({ image: null });
      await service.deleteChefPhotosImage({
        _id: this.state.chef._id,
        imagePath,
      });
      this.props.hide();
    } catch (err) {
      console.log(err);
    }
  }

  async loadHalallaGallery() {
    this.setState({ loading: true });
    try {
      const photos = await service.loadChefPhotos(this.state.chef._id);
      if (photos.length > 0) {
        const base64 = await service.loadImageBase64(photos[0]);
        this.setState({
          image: {
            path: photos[0],
            base64,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  }

  componentDidMount() {
    this.loadHalallaGallery();
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.setState({ crop });
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop() {
    if (this.imageRef && this.state.crop?.width && this.state.crop?.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        this.state.crop,
        "newFile.jpeg",
      );

      this.imageRef = undefined;
      this.setState({ image: { base64: croppedImageUrl }, src: undefined });
    }
  }

  async saveImage() {
    const response = await fetch(this.state.image.base64);
    const blob = await response.blob();
    const reader = new FileReader();

    reader.onload = async () => {
      const base64data = reader.result;

      try {
        await service.saveChefGalleryPhotos({
          _id: this.state.chef._id,
          base64: base64data,
        });
        this.props.hide();
      } catch (err) {
        alert(err.message);
      }
    };

    reader.onerror = () => {
      console.log("error");
      this.props.hide();
    };

    reader.readAsDataURL(blob);
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  renderMain() {
    return (
      <Card className="strpied-tabled-with-hover" style={{ padding: 20 }}>
        <Card.Header style={{ display: "flex" }}>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
          <div
            style={{ display: "flex", width: "100%", justifyContent: "right" }}
          >
            <button className="btn" onClick={this.props.hide}>
              {properties.commonLabel.cancelBtnLabel}
            </button>
            {this.state.image && (
              <button className="btn" onClick={() => this.saveImage()}>
                {properties.commonLabel.saveBtnLabel}
              </button>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {this.state.image && (
            <div style={{ display: "inline-block", textAlign: "center" }}>
              <img
                style={{ width: 500 }}
                src={this.state.image.base64}
                alt="Chef"
              />
              <button
                className="btn"
                style={{ marginTop: -80 }}
                onClick={() => this.deleteImage(this.state.image.path)}
              >
                Delete
              </button>
              <br />
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }

  renderImageCrop() {
    return (
      <Card className="strpied-tabled-with-hover">
        <Card.Header style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{ marginTop: 30 }}
            className="saveAgencyBtn"
            onClick={() => this.setState({ src: undefined })}
          >
            {properties.commonLabel.cancelBtnLabel}
          </button>
          <button
            style={{ marginTop: 30 }}
            className="saveAgencyBtn"
            onClick={() => this.makeClientCrop()}
          >
            {properties.commonLabel.nextBtnLabel}
          </button>
        </Card.Header>
        <Card.Body>
          {this.state.src && (
            <ReactCrop
              aspect={1}
              minWidth={100}
              minHeight={100}
              crop={this.state.crop}
              onChange={this.onCropChange}
              onComplete={this.onCropComplete}
            >
              <img
                src={this.state.src}
                onLoad={(e) => this.onImageLoaded(e.target)}
                alt="Crop"
              />
            </ReactCrop>
          )}
        </Card.Body>
      </Card>
    );
  }

  render() {
    return (
      <Container fluid style={{ padding: 10 }}>
        <Row>
          <Col md="12">
            <p style={{ fontSize: 20, marginLeft: 5 }}>
              Photo - {this.state.chef.firstName} {this.state.chef.lastName} -
              (Chef)
            </p>
            {this.state.src ? this.renderImageCrop() : this.renderMain()}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Photos;
