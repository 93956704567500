import React from "react";
import "../../../views/Style.css";
import {Button, Col, Container, Dropdown, Modal, Nav, Row,} from "react-bootstrap";
import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {MdDeleteSweep, MdEditDocument, MdMyLocation, MdPhoto, MdPhotoLibrary, MdSchedule} from "react-icons/md";
import {properties} from "../../../properties.js";
import {SLoader} from "../../../components/CommonComponents";
import Photos from "./photos";
import Gallery from "./gallery";
import HalallaScreen from "./halalla";
import LocationScreen from "./location";
import ScheduleScreen from "./schedule";
import {loadHalallas} from "../../../services/Service.js";

export class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            criteria: {page: 1, size: 10},
            showHalalla: false,
            showLocation: false,
            showGallery: false,
            showPhotos: false,
            showSchedule: false,
            halallas: [],
            focusedInput: null,
        };
        this.hideGallery = this.hideGallery.bind(this);
        this.hideHalalla = this.hideHalalla.bind(this);
        this.hideLocation = this.hideLocation.bind(this);
        this.hideSchedule = this.hideSchedule.bind(this);
        this.hidePhotos = this.hidePhotos.bind(this);
    }


    componentDidMount() {
        this.loadHalallas(this.state.criteria)

    }

    loadPagination(criteria) {

        let paginationViews = undefined;

        if (criteria?.totalPages && criteria.totalPages > 1) {

            const subViews = [];
            let size = criteria.size;
            const count = criteria.totalPages > 10 ? (criteria.page + 9) : criteria.totalPages;

            for (let i = criteria.page; i <= count; i++) {

                let page = i;
                console.log('page-------------', page);

                if (page === criteria.page) {
                    subViews.push(<li className="page-item active">
                        <a className="page-link" href="#" onClick={() => {
                            this.loadHalallas({page, size, search: criteria.search})
                        }}>
                            {page}<span className="sr-only">(current)</span>
                        </a>.
                    </li>)
                } else {
                    subViews.push(<li className="page-item">
                        <a className="page-link" href="#" onClick={() => {
                            this.loadHalallas({page, size, search: criteria.search})
                        }}>
                            {page}<span className="sr-only">(current)</span>
                        </a>
                    </li>)
                }


            }

            paginationViews = (<div key={'key-admin-pagination-halalla-100'}
                                    style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                <nav aria-label="...">
                    <ul className="pagination">
                        <li className={'page-item ' + (criteria.page > 1 ? '' : 'disabled')}>
                            <a
                                className="page-link"
                                href="#"
                                onClick={() => {
                                    if (criteria.page > 1) {
                                        this.loadHalallas({page: criteria.page - 1, size, search: criteria.search});
                                    }
                                }}
                            >
                                Previous
                            < /a>
                        </li>
                        {subViews}
                        <li className={'page-item ' + (criteria.page < criteria.totalPages ? '' : 'disabled')}>
                            <a className="page-link" href="#" onClick={() => {
                                if (criteria.page > 1) {
                                    this.loadHalallas({page: criteria.page + 1, size, search: criteria.search});
                                }
                            }}>
                                Next
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>)


            console.log(paginationViews);
        }


        this.setState({paginationViews});

    }

    async loadHalallas(criteria) {
        try {
            this.setState({loading: true});
            criteria = await service.loadHalallas(criteria);
            const halallas = criteria.halallas; // Extract the array from the response
            console.log(criteria)
            this.setState({criteria, halallas});
            this.loadPagination(criteria);
        } catch (err) {
            console.log(err);
        }
        this.setState({loading: false});
    }

    showEdit(i) {
        const halalla = this.state.halallas[i];
        this.setState({
            showHalalla: true,
            selectedHalallaId: halalla._id
        });
    }

    showLocation(i) {
        const location = this.state.halallas[i];
        console.log("---Edit location---", location);
        this.setState({
            showLocation: true,
            selectedHalallaId: location._id,
            selectedHalalla: location
        });
    }

    showSchedule(i) {
        const schedule = this.state.halallas[i];
        console.log("---Edit Schedule---", schedule);
        this.setState({
            showSchedule: true,
            selectedHalallaId: schedule._id,
            selectedHalalla: schedule
        });
    }

    showGallery(i) {
        const halalla = this.state.halallas[i];
        this.setState({
            showGallery: true,
            selectedHalalla: halalla
        });
    }


    showPhotos(i) {
        const halalla = this.state.halallas[i];
        this.setState({
            showPhotos: true,
            selectedHalalla: halalla
        });
    }


    showDelete(i) {
        const selectedHalalla = this.state.halallas[i];
        this.setState({
            showDeleteModal: true,
            selectedHalalla,
            title: selectedHalalla.title ? selectedHalalla.title : selectedHalalla.zTitle,
        });
    }

    async hideGallery() {
        this.setState({
            loading: false, showGallery: false, selectedHalalla: undefined
        });
    }


    async hidePhotos() {
        this.setState({
            loading: false, showPhotos: false, selectedHalalla: undefined
        });
    }

    renderGalleryScreen() {
        return <Gallery halalla={this.state.selectedHalalla} hide={this.hideGallery}/>;
    }


    renderPhotosScreen() {
        return <Photos halalla={this.state.selectedHalalla} hide={this.hidePhotos}/>;
    }

    async hideHalalla() {
        this.setState({
            loading: false, showHalalla: false, selectedHalallaId: undefined
        });
        this.loadHalallas();
    }

    renderHalallaScreen() {
        return <HalallaScreen id={this.state.selectedHalallaId} hide={this.hideHalalla}/>;
    }

    async hideLocation() {
        try {
            this.setState({loading: true});

        } catch (e) {
            console.log(e);
        }

        this.setState({
            loading: false, showLocation: false, selectedHalallaId: undefined
        });
    }

    renderLocationScreen() {
        return <LocationScreen id={this.state.selectedHalallaId} halalla={this.state.selectedHalalla}
                               hide={this.hideLocation}/>;
    }


    async hideSchedule() {
        this.setState({
            loading: false,
            showSchedule: false,
            selectedHalallaId: undefined
        });
    }

    renderScheduleScreen() {
        return <ScheduleScreen id={this.state.selectedHalallaId} halalla={this.state.selectedHalalla}
                               hide={this.hideSchedule}/>;
    }

    handleInputFocus = (inputName) => {
        this.setState({focusedInput: inputName});
    };

    handleInputBlur = () => {
        this.setState({focusedInput: null});
    };


    renderDeleteModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showDeleteModal}
                onHide={() => {
                }}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <i style={{marginRight: 10}} class="fa fa-trash"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do You Want to Delete Selected Halalla Item</span>
                    <p>{this.state.title}</p>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={async () => {

                            this.setState({
                                showDeleteModal: false, title: undefined, selectedHalallas: undefined
                            })
                        }}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={async () => {
                            try {
                                await service.deleteHalala({_id: this.state.selectedHalalla._id});
                                this.loadHalallas(this.state.criteria)
                            } catch (err) {
                                console.log(err)
                            }
                            this.setState({
                                showDeleteModal: false, title: undefined, selectedHalallas: undefined
                            })
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        );
    }


    render() {

        if (this.state.showHalalla) {
            return this.renderHalallaScreen();
        } else if (this.state.showLocation) {
            return this.renderLocationScreen();
        } else if (this.state.showSchedule) {
            return this.renderScheduleScreen();
        } else if (this.state.showGallery) {
            return this.renderGalleryScreen();
        } else if (this.state.showPhotos) {
            return this.renderPhotosScreen();
        } else {
            var context = this;
            return <>{this.renderDeleteModal()}<Container fluid>
                <Row
                    style={{
                        display: "flex", flexDirection: "row", marginBottom: 20,
                    }}
                >
                    <Col md="6">
                        <div style={{display: "flex", justifyContent: "left"}}>
                            <label className="main-heading">
                                Halallas
                            </label>
                        </div>
                    </Col>
                    <Col>
                        <div
                            className="search-container"
                            style={{
                                border: this.state.focusedInput === 'search' ? '2px solid #b82e3a' : '',
                                borderRadius: 6
                            }}
                        >
                            <input
                                autoComplete="off"
                                type='search'
                                name="search"
                                value={this.state.search}
                                placeholder="Search Halalla"
                                onChange={(e) => {
                                    const search = e.target.value;
                                    const criteria = this.state.criteria;
                                    criteria.search = search;
                                    this.setState({criteria, search});
                                    this.loadHalallas(criteria);
                                }}
                                className="search-input"
                                onFocus={() => this.handleInputFocus('search')}
                                onBlur={this.handleInputBlur}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        alert(1)
                                    }
                                }}
                            />
                            <i style={{
                                color: this.state.focusedInput === 'search' ? '#b82e3a' : '',
                            }}
                               className='fa fa-search'
                               id="search-icon"
                            ></i>

                        </div>
                    </Col>
                    <div className="d-none d-md-block"> {/* Applies only to screens medium-sized and larger */}
                        <Col>
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <button
                                    className="add-btn"
                                    type="submit"
                                    onClick={() => {
                                        this.setState({
                                            showHalalla: true,
                                            active: true
                                        });
                                    }}
                                >
                                    Add Halalla
                                    <i className="add-btn-icon fa fa-plus-circle"></i>{" "}
                                </button>
                            </div>
                        </Col>
                    </div>

                    {/* Conditionally render the button only in mobile view */}
                    <div className="d-md-none"> {/* Applies only to screens smaller than md (medium) */}
                        <Col>
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <button
                                    className="add-btn-mbl"
                                    type="submit"
                                    onClick={() => {
                                        this.setState({
                                            showHalalla: true,
                                            active: true
                                        });
                                    }}
                                >
                                    <i className="add-btn-icon-mbl fa fa-plus-circle"></i>{" "}
                                </button>
                            </div>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col md="12">
                        {this.state.loading && <SLoader/>}
                        {!this.state.loading && (
                            <table className="custom-table">
                                <thead>
                                <tr>
                                    <th className="centered-heading">S.No</th>
                                    <th className="centered-heading">Restaurants</th>
                                    <th className="centered-heading">Location</th>
                                    <th className="centered-heading">Action</th>
                                </tr>
                                </thead>
                                <tbody>


                                {!this.state.loading && this.state.halallas && this.state.halallas.map((item, index) => {
                                    // console.log("---LIST------", item);
                                    return (<tr key={'key-halallas-table-' + index}>
                                        <td className="centered-data">{index + 1}</td>
                                        <td className="centered-data">{item.title ? item.title : item.zTitle}</td>
                                        <td className="centered-data">{item.zLocation?.address}</td>
                                        <td className="centered-data">
                                            <Dropdown style={{
                                                justifyContent:'center',
                                                display:'flex'
                                            }}>
                                                <Dropdown.Toggle
                                                    as={Nav.Link}
                                                    data-toggle="dropdown"
                                                    id="dropdown-67443507"
                                                    variant="default"
                                                    className="m-0"
                                                    style={{color: "#000"}}
                                                >
                                                    <label>
                                                        {properties.commonLabel.tableHeaderAction}
                                                    </label>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={context.showEdit.bind(context, index)}
                                                    >
                                                        <MdEditDocument
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        Modify
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={context.showLocation.bind(context, index)}
                                                    >
                                                        <MdMyLocation
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        Location
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={context.showSchedule.bind(context, index)}
                                                    >
                                                        <MdSchedule
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        Schedule
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                        onClick={context.showGallery.bind(context, index)}
                                                    >
                                                        <MdPhotoLibrary
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        {properties.commonLabel.actionGallery}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={context.showPhotos.bind(context, index)}
                                                    >
                                                        <MdPhoto
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        Photos
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={context.showDelete.bind(
                                                            context,
                                                            index
                                                        )}

                                                    >
                                                        <MdDeleteSweep
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>);
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th className="centered-heading"></th>
                                    <th className="centered-heading"></th>
                                    <th className="centered-heading">Total Records</th>
                                    <th className="centered-heading">{this.state.criteria?.totalRecords}</th>
                                </tr>
                                </tfoot>
                            </table>
                        )}

                        {!this.state.loading && this.state.paginationViews}


                    </Col>
                </Row>
            </Container></>
        }
    }
}

export default Index;
