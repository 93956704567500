export const properties = {

    common: {
        halalla: 'Halalla',
        halallas: 'Halallas',
        customer: 'Customer',
        resetPassword: "Reset Password",
        idLabel: "Id",
        name: 'Nom',
        slug: 'slug',
        meta: 'méta',
        value: 'Valeur',
        menuName: "Nom du menu",

    },

    login: {
        loginHeader: "Login",
        email: "E-mail",
        password: "Enter Password",
        submit: "Login",
    },


    menu: {
        mainHeading: "Menu",
        header: "Header",
        footer: "Footer",
        showMenuItem: 'Show',
        menuItem: {
            back: 'back',
            name: 'Nom anglais',
            frName: 'Nom français',
            path: "Détail l'URL",
            delete: 'Delete',
            option: 'Option de menu',
        },
        menuDetails: "Détails des menus",
        headerMenuName: "Nom du menu",
    },


    product: {

        title: 'Titre',
        description: 'Description',
        activated: 'Activated',
        path: "Détail l'URL",
        addEditHeader: "Détails du produit",
        mainHeadingProduct: "Des produits",
        mainHeadingProductAttribute: "Produits - Attributs",
        mainHeadingProductVariants: "Produit - <> - Variantes",
        placeholder: {
            title: 'title',
            path: "Détail l'URL",
            salePrice: '0.00',
            comparePrice: '0.00'
        },
        salePrice: 'Prix de vente',
        comparePrice: 'Comparer au prix',
        collection: 'Collections',
        tags: 'Tags',
        category: 'Catégories',
        brand: 'Marque',
        weight: "Poids en grammes",
        name: "Nom des produits",
        attributes: "Les attributs",
        variants: "Variantes",
        vat: "VAT %",

    },

    collection: {
        title: 'Titre',
        description: 'Description',
        activated: 'Activated',
        path: "Détail l'URL",
        addEditHeader: "Détails du produit",
        mainHeadingCollection: "Collections",
        placeholder: {
            title: 'title',
            path: "Détail l'URL",
        },
        name: "Nom des collections",
    },

    commonLabel: {
        saveBtnLabel: "Save",
        cancelBtnLabel: "Back",
        backBtnLabel: "Back",
        nextBtnLabel: "Save",
        activatedLabel: "Activé",
        showLabel: "Show",
        addBtnLabel: "Ajouter",
        tableHeaderAction: "Action",
        actionEdit: "Modifier",
        actionDelete: "Effacer",
        actionLocation: "Emplacement",
        actionGallery: "Gallery",
        actionCharges: "Charges",
        actionVariants: "Variants",
        actionAttributes: "Attributes",
        actionTiming: "Horaire",
        actionVehicles: "Véhicules",
        phoneErrorLabel: "Entrez un numéro de téléphone valide",
        emptyFieldLabel: "Champ vide non autorisé",
        errorLabel: "E-mail déjà enregistré",
        error1Label: "Échec de l'enregistrement",
        path: "Détail l'URL",
        idLabel: "Id",
        createAtLabel: "Compte crée le",
        chargesLabel: "Tarifs",
        previousBtnLabel: "Précédente",
        passwordLabel: "Mot de passe",
        newPasswordLabel: "Nouveau mot de passe",
        changePassword: "Changer le mot de passe",
        enterPassword: "Entrer le mot de passe",

    },

    deleteModal: {
        deleteHeader: "Voulez-vous supprimer ces donnée",
        deleteYes: "Oui",
        deleteNo: "Non",
    },

    agency: {
        mainHeadingAgency: "Agences",
        mainHeadingAgencyVehicles: "Agences Véhicules",
        locationScreenLabel: "Emplacement",
        charges: "Tarifs",

        //table
        agencyId: "Id",
        agencyName: "Nom agence",
        agencyEmail: "E-mail",
        agencyDesc: "Description",
        agencyContactName: "Contact agence",
        agencyContactPhone: "Contact téléphone",

        //AddEdit
        addEditHeader: "Détails de l'agences",
        nameALabel: "Nom agence",
        emailALabel: " E-mail",
        descALabel: "Description",
        contactNameALabel: "Contact agence",

    },

    vehicle: {
        mainHeadingVehicle: "Véhicules",

        //table
        vehicleId: "Id",
        vehicleName: "Nom du véhicule",
        vehicleHourlyAmount: "Taux horaire",
        vehicleDailyAmount: "Taux journalier",
        vehicleDesc: "Description",


        //AddEdit
        addEditVehicleHeader: "Détails du véhicule",
        selectCategoryLabel: "Choisir une catégorie",
        nameLabel: "Nom du véhicule",
        amountLabel: "Montant",
        descLabel: "Description",

    },

    category: {
        mainHeadingCategory: "Catégories",
        categoryId: "Id",
        categoryName: "Catégories véhicules",
        categoryDesc: "Description",
        addEditCategoryHeader: "Détails du véhicule",
        nameCLabel: "Catégories véhicules",
        descCLabel: "Description",
        name: "Catégories Nom",

    },

    gallery: {
        mainHeadingGallery: "Gallery",

    },

    timing: {
        mainHeadingTiming: "Horaire",

    },

    order: {
        mainHeadingOrder: "Commande",
        emailOrderLabel: " E-mail",
        phoneOrderLabel: "Téléphone",
        vechilesOrderLabel: "Véhicules",
        amountOrderLabel: "Montant",
        modeOrderLabel: "Paiement",

    },

    client: {
        mainHeadingClient: "Clients",
        nameClientLabel: " Nom",
        emailClientLabel: "E-mail",
        phoneClientLabel: "Téléphone",

    },

    resetPassword: {
        mainHeadingResetPassword: "Réinitialisation du mot de passe",
        password: "Mot de passe",
        newPassword: "Nouveau mot de passe",

    },

    dashboard: {
        mainDashboard: "Tableau de commande",
        customers: "Clients",
        shops: "Shops",
        resetPassword: "Réinitialiser mot de passe",
    },

    shipping: {
        headerTitle: "Mode de livraison",
        newFormTitle: "Formulaire de méthode d'expédition",
        shippingId: "Numéro d'expédition",
        shippingName: "Nom pour la livraison",
        nameTitle: "Nom de l'expéditeur",
        descriptionTitle: "Description de l'expéditeur",
        amountTitle: "Montant",
        charges: "Des charges",
        weight: "Poids",

    },

    brand: {
        brandHeader: "Marques",
        brandName: "Nom des marques"
    }
};
