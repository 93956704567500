import React from "react";
import "../../../views/Style.css";
import {Button, Col, Container, Dropdown, Modal, Nav, Row,} from "react-bootstrap";
import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {MdDeleteSweep, MdEditDocument, MdMyLocation, MdPhoto, MdPhotoLibrary, MdSchedule} from "react-icons/md";
import {properties} from "../../../properties.js";
import {SLoader} from "../../../components/CommonComponents";
import ChefForm from "./Form";
import {FaUserEdit} from "react-icons/fa";
import {deleteChefForm, loadChefForms} from "../../../services/Service.js";
import {NotificationManager} from "react-notifications";

export class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            criteria: {page: 1, size: 10},
            showChefForm: false,
            halallas: [],
            focusedInput: null,
            showApproveModal: false,
            showDeleteModal: false,
        };
        this.hideChefForm = this.hideChefForm.bind(this);

    }


    componentDidMount() {
        this.loadChefForms(this.state.criteria)

    }

    loadPagination(criteria) {

        let paginationViews = undefined;

        if (criteria?.totalPages && criteria.totalPages > 1) {

            const subViews = [];
            let size = criteria.size;
            const count = criteria.totalPages > 10 ?
                (window.innerWidth < 700 ? (criteria.page + 4) : (criteria.page + 9))
                : criteria.totalPages;

            for (let i = criteria.page; i <= count; i++) {

                let page = i;
                console.log('page-------------', page);

                if (page === criteria.page) {
                    subViews.push(
                        <li className="page-item active">
                            <a className="page-link" href="#" onClick={() => {
                                this.loadChefForms({page, size})
                            }}>
                                {page}<span className="sr-only">(current)</span>
                            </a>.
                        </li>
                    )
                } else {
                    subViews.push(<li className="page-item">
                            <a className="page-link" href="#" onClick={() => {
                                this.loadHalallaForms({page, size})
                            }}>
                                {page}<span className="sr-only">(current)</span>
                            </a>
                        </li>
                    )
                }


            }

            paginationViews = (
                <div key={'key-admin-pagination-halalla-100'}
                     style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                    <nav aria-label="...">
                        <ul className="pagination">
                            <li className={'page-item ' + (criteria.page > 1 ? '' : 'disabled')}>
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => {
                                        if (criteria.page > 1) {
                                            this.loadChefForms({page: criteria.page - 1, size});
                                        }
                                    }}
                                >
                                    Previous
                                < /a>
                            </li>
                            {
                                subViews
                            }
                            <li className={'page-item ' + (criteria.page < criteria.totalPages ? '' : 'disabled')}>
                                <a className="page-link" href="#" onClick={() => {
                                    if (criteria.page > 1) {
                                        this.loadChefForms({page: criteria.page + 1, size});
                                    }
                                }}>
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>)


            console.log(paginationViews);
        }


        this.setState({paginationViews});

    }

    async loadChefForms(criteria) {
        try {
            this.setState({loading: true});
            criteria = await service.loadChefForms(criteria);
            const chefs = criteria.ChefForms;
            console.log("ChefForms---------", chefs);
            this.setState({criteria, chefs});
            this.loadPagination(criteria);
        } catch (err) {
            console.log(err);
        }
        this.setState({loading: false});
    }



    showEdit(i) {
        const chef = this.state.chefs[i];
        this.setState({
            showChefForm: true,
            selectedChefId: chef._id
        });
    }

    showApproval(i) {
        const chef = this.state.chefs[i];
        this.setState({
            showApproveModal: true,
            selectedChefId: chef._id
        });
    }

    showDelete(i) {
        const chef = this.state.chefs[i];
        this.setState({
            showDeleteModal: true,
            selectedChefId: chef._id
        });
    }


        async hideChefForm() {
        this.setState({
            loading: false, showChefForm: false, selectedHalallaId: undefined
        });
        this.loadChefForms();
    }

    renderChefFormScreen() {
        return <ChefForm id={this.state.selectedChefId} hide={this.hideChefForm}/>;
    }


    renderDeleteModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showDeleteModal}
                onHide={() => {}}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <i style={{marginRight: 10}} class="fa fa-trash"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do you want to delete this chef form</span>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => this.setState({
                            showDeleteModal: false,
                        })}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={async () => {
                            try {
                                await service.deleteChefForm({_id: this.state.selectedChefId});
                                this.loadChefForms(this.state.criteria)
                            } catch (err) {
                                console.log(err)
                            }
                            this.setState({
                                showDeleteModal: false, selectedChefId: undefined
                            })
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        );
    }

    renderApproveModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showApproveModal}
                onHide={() => {}}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <img
                            src={require("../../../assets/img/chef.png")}
                            alt="Delete Icon"
                            style={{width: '100',marginBottom:22}}
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do you want to approve this chef form</span>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => this.setState({
                            showApproveModal: false,
                        })}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={
                        async () => {
                            try {
                                this.setState({ loader: true });

                                const approvalForm = {
                                    _id: this.state.selectedChefId,
                                };
                                await service.approveChefForm(approvalForm);
                                this.loadChefForms(this.state.criteria)
                                console.log("Chef Approval---------", approvalForm);
                                NotificationManager.success('Chef Approved Successfully', 'Saved');

                                this.setState({ loading: false, showApproveModal: false });
                            } catch (err) {
                                console.log("Error----------", err);

                                // Handle the error state or display an error notification if needed
                                this.setState({ loading: false });
                            }
                        }}
                    >
                        Approve
                    </Button>
                </div>
            </Modal>
        );
    }


    render() {

        if (this.state.showChefForm) {
            return this.renderChefFormScreen();
        } else if (this.state.showLocation) {
            return this.renderLocationScreen();
        } else if (this.state.showSchedule) {
            return this.renderScheduleScreen();
        } else if (this.state.showGallery) {
            return this.renderGalleryScreen();
        } else if (this.state.showPhotos) {
            return this.renderPhotosScreen();
        } else {
            var context = this;
            return <>
                {this.renderDeleteModal()}
                {this.renderApproveModal()}
                <Container fluid>
                    <Row
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 20,
                        }}
                    >
                        <Col>
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <label className="main-heading">
                                    Chef Form Approval
                                </label>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <table className="custom-table">
                                <thead>
                                <tr>
                                    <th className="centered-heading">S.No</th>
                                    <th className="centered-heading">Chef Name</th>
                                    <th className="centered-heading">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.chefs &&
                                    this.state.chefs.map((item, index) => {
                                        console.log("--APPROVAL-LIST------", item);
                                        return (
                                            <tr key={'key-halallas-table-' + index}>
                                                <td className="centered-data">{index + 1}</td>
                                                <td className="centered-data">{item.firstName} {item.lastName}</td>
                                                <td className="centered-data">
                                                    <Dropdown style={{
                                                        justifyContent:'center',
                                                        display:'flex'
                                                    }}>
                                                        <Dropdown.Toggle
                                                            as={Nav.Link}
                                                            data-toggle="dropdown"
                                                            id="dropdown-67443507"
                                                            variant="default"
                                                            className="centered-data"
                                                            style={{
                                                                color: "#000",
                                                        }}
                                                        >
                                                            <label>
                                                                {properties.commonLabel.tableHeaderAction}
                                                            </label>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={context.showEdit.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Edit Form
                                                            </Dropdown.Item>

                                                            <Dropdown.Item
                                                                onClick={context.showApproval.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Approve
                                                            </Dropdown.Item>

                                                            <Dropdown.Item
                                                                onClick={context.showDelete.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Delete
                                                            </Dropdown.Item>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {this.state.paginationViews}


                        </Col>
                    </Row>
                </Container>
            </>
        }
    }
}

export default Index;
