import React from "react";
import "../../../views/Style.css";
// react-bootstrap components
import {Button, Card, Col, Container, Dropdown, Form, InputGroup, Modal, Nav, Row,} from "react-bootstrap";

import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {FaBuilding, FaImages, FaTrash, FaUserEdit} from "react-icons/fa";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import {properties} from "../../../properties.js";
import {SLoader} from "../../../components/CommonComponents";
import Editor from "./../Editor";
import GoogleMapReact from 'google-map-react';
import HalallaGalleryOld from "../HalallaGalleryOld";
import HalallaScreen from "./halalla";
import {loadHalallaSchedules, saveHalallaSchedules} from "../../../services/Service.js";
import {toast, ToastContainer} from "react-toastify";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import 'react-notifications-component/dist/theme.css';
import {Vortex} from "react-loader-spinner";


const days = [
    {value: '', label: 'Day', disabled: true},
    {value: 'SUN', label: 'SUN'},
    {value: 'MON', label: 'MON'},
    {value: 'TUE', label: 'TUE'},
    {value: 'WED', label: 'WED'},
    {value: 'THU', label: 'THU'},
    {value: 'FRI', label: 'FRI'},
    {value: 'SAT', label: 'SAT'}

];

const timings = [
    { value: '', label: 'Select Time', disabled: true },
    { value: "12:00a", label: "12:00a" },
    { value: "12:30a", label: "12:30a" },
    { value: "01:00a", label: "1:00a" },
    { value: "01:30a", label: "1:30a" },
    { value: "02:00a", label: "2:00a" },
    { value: "02:30a", label: "2:30a" },
    { value: "03:00a", label: "3:00a" },
    { value: "03:30a", label: "3:30a" },
    { value: "04:00a", label: "4:00a" },
    { value: "04:30a", label: "4:30a" },
    { value: "05:00a", label: "5:00a" },
    { value: "05:30a", label: "5:30a" },
    { value: "06:00a", label: "6:00a" },
    { value: "06:30a", label: "6:30a" },
    { value: "07:00a", label: "7:00a" },
    { value: "07:30a", label: "7:30a" },
    { value: "08:00a", label: "8:00a" },
    { value: "08:30a", label: "8:30a" },
    { value: "09:00a", label: "9:00a" },
    { value: "09:30a", label: "9:30a" },
    { value: "10:00a", label: "10:00a" },
    { value: "10:30a", label: "10:30a" },
    { value: "11:00a", label: "11:00a" },
    { value: "11:30a", label: "11:30a" },
    { value: "12:00p", label: "12:00p" },
    { value: "12:30p", label: "12:30p" },
    { value: "01:00p", label: "1:00p" },
    { value: "01:30p", label: "1:30p" },
    { value: "02:00p", label: "2:00p" },
    { value: "02:30p", label: "2:30p" },
    { value: "03:00p", label: "3:00p" },
    { value: "03:30p", label: "3:30p" },
    { value: "04:00p", label: "4:00p" },
    { value: "04:30p", label: "4:30p" },
    { value: "05:00p", label: "5:00p" },
    { value: "05:30p", label: "5:30p" },
    { value: "06:00p", label: "6:00p" },
    { value: "06:30p", label: "6:30p" },
    { value: "07:00p", label: "7:00p" },
    { value: "07:30p", label: "7:30p" },
    { value: "08:00p", label: "8:00p" },
    { value: "08:30p", label: "8:30p" },
    { value: "09:00p", label: "9:00p" },
    { value: "09:30p", label: "9:30p" },
    { value: "10:00p", label: "10:00p" },
    { value: "10:30p", label: "10:30p" },
    { value: "11:00p", label: "11:00p" },
    { value: "11:30p", label: "11:30p" },
];



export class schedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            halallaId: props.id,
            halalla: props.halalla,
            loading: false,
            halallas: [],
            sunClosed: '',
            monClosed: '',
            tueClosed: '',
            wedClosed: '',
            thurClosed: '',
            friClosed: '',
            satClosed: '',
            selectedDay: "",
            startTime: "",
            endTime: "",
            isClosed: false,
            scheduleHalalla: [], // Your existing schedule state
            savedSchedule: [],
            loader: false
        };

    }

    async loadHalallaSchedule() {
        try {
            this.setState({loading: true})
            const scheduleHalalla = await service.loadHalallaSchedules(this.state.halallaId);
            console.log("Halalla Schedule---------", scheduleHalalla);
            this.setState({scheduleHalalla});
        } catch (err) {
            console.log('Error', err)
        }
        this.setState({loading: false})

    }

    componentDidMount() {
        this.loadHalallaSchedule();
    }


    handleCheckboxChange = (closed) => {
        this.setState({isClosed: closed, startTime: "-", endTime: "-"});
    };



    handleSaveButtonClick = () => {
        const {selectedDay, startTime, endTime, isClosed, scheduleHalalla} = this.state;

        if (!selectedDay || !startTime || !endTime) {
            NotificationManager.error('Please select a valid day and time', 'Error');
            return;
        }

        // Create a new day object
        const newDay = {
            index: scheduleHalalla.length, // Assign the current length as the initial index
            day: selectedDay,
            startTime: isClosed ? "-" : startTime,
            endTime: isClosed ? "-" : endTime,
            isClosed: isClosed || undefined, // Check if isClosed is explicitly set
        };

        // Remove existing occurrences of the selected day if isClosed is explicitly set to true
        let updatedSchedule = isClosed ? scheduleHalalla.filter((day) => day.day !== selectedDay) : scheduleHalalla;

        // Find the index to insert the new day based on the specified schedule
        const insertIndex = updatedSchedule.findIndex(day => {
            const daysOrder = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
            return daysOrder.indexOf(day.day) > daysOrder.indexOf(selectedDay);
        });

        // Update the index for the new day based on the insertIndex
        newDay.index = insertIndex !== -1 ? insertIndex : updatedSchedule.length;

        // Insert the new day at the correct position
        if (insertIndex !== -1) {
            updatedSchedule.splice(insertIndex, 0, newDay);

            for (let i = 0; i < updatedSchedule.length; i++) {
                updatedSchedule[i].index = i;
            }


        } else {
            // If the day doesn't exist, add it to the end of the schedule
            updatedSchedule.push(newDay);
        }

        // Update the state
        this.setState({
            scheduleHalalla: updatedSchedule,
            selectedDay: "",
            startTime: "",
            endTime: "",
            isClosed: false,
        }, () => {
            // Log the updated state to the console
            console.log("Updated State:", this.state.scheduleHalalla);
        });
    };


    handleDeleteButtonClick = (index) => {
        const updatedSchedule = [...this.state.scheduleHalalla];
        updatedSchedule.splice(index, 1); // Remove the item at the specified index
        this.setState({scheduleHalalla: updatedSchedule}, () => {
            // Log the updated state to the console after deletion
            console.log("Updated State after deletion:", this.state.scheduleHalalla);
        });
    };

    SaveSchedule = async () => {
        // Log the updated schedule to the console before saving
        try {
            this.setState({ loader: true });
            await service.saveHalallaSchedules({
                _id: this.state.halalla._id,
                schedules: this.state.scheduleHalalla
            })
            NotificationManager.success('Schedule Saved', 'Saved');
            this.setState({ loader: false });
            this.props.hide()
        }
        catch (err){
            console.log(err);
        }

    };


    render() {

        if (this.state.loading) {
            return <SLoader/>;
        } else {
            var context = this;
            return (
                <>
                    <Container fluid>
                        <NotificationContainer/>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Header>
                                        <div  className="schedule-header-container">
                                            <Card.Title as="h4" className="schedule-header">
                                                {`Schedule: ${this.state.halalla.title ? this.state.halalla.title : this.state.halalla.zTitle}`}
                                            </Card.Title>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>


                                            <div className="timing-section">
                                                <Col className="pr-1" md="2">
                                                    <Form.Group>
                                                        <label>Day</label>
                                                        <InputGroup>
                                                            <InputGroup.Text className="select-icon">
                                                                <FaBuilding size={20}/>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                as="select"
                                                                value={this.state.selectedDay}
                                                                onChange={(e) => this.setState({selectedDay: e.target.value})}
                                                            >
                                                                {days.map((days) => (
                                                                    <option key={days.label} value={days.label}>
                                                                        {days.label}
                                                                    </option>))}
                                                            </Form.Control>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col className="pr-1" md="3">
                                                    <Form.Group>
                                                        <label>Open</label>
                                                        <InputGroup>
                                                            <InputGroup.Text className="select-icon">
                                                                <FaBuilding size={20}/>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                as="select"
                                                                value={this.state.startTime}
                                                                onChange={(e) => this.setState({startTime: e.target.value})}
                                                            >
                                                                {timings.map((timings) => (
                                                                    <option key={timings.label} value={timings.value}>
                                                                        {timings.label}
                                                                    </option>))}
                                                            </Form.Control>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>

                                                <Col className="pr-1" md="3">
                                                    <Form.Group>
                                                        <label>Close</label>
                                                        <InputGroup>
                                                            <InputGroup.Text className="select-icon">
                                                                <FaBuilding size={20}/>
                                                            </InputGroup.Text>
                                                            <Form.Control
                                                                as="select"
                                                                value={this.state.endTime}
                                                                onChange={(e) => this.setState({endTime: e.target.value})}
                                                            >
                                                                {timings.map((timings) => (
                                                                    <option key={timings.label} value={timings.value}>
                                                                        {timings.label}
                                                                    </option>))}
                                                            </Form.Control>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <div className="schedule-checkbox">
                                                    <Checkbox
                                                        onChange={(closed) => {
                                                            this.handleCheckboxChange(closed);
                                                        }}
                                                        checked={this.state.isClosed}
                                                        value={this.state.productActivated}
                                                        icon={<div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,
                                                                backgroundColor: "black",
                                                                alignSelf: "stretch",
                                                            }}
                                                        >
                                                            <Icon.FiCheck color="yellow" size={20}/>
                                                        </div>}
                                                        borderColor="gray"
                                                        // borderWidth={0}
                                                        borderRadius={20}
                                                        style={{overflow: "hidden"}}
                                                        size={20}
                                                        label="Closed?"
                                                    />
                                                </div>
                                                <button
                                                    style={{marginTop: 30}}
                                                    className="saveTimeBtn"
                                                    onClick={this.handleSaveButtonClick}
                                                >
                                                    <i className=" fa fa-solid fa-plus"></i>
                                                </button>
                                            </div>


                                        </Row>
                                        {/*<Col className="pr-1 new-table-container" md="12" style={{ marginTop: 30, display:'block', overflow:'auto hidden',marginBottom:20 }}>*/}
                                        <table className="custom-table">
                                            <thead>
                                            <tr>
                                                <th className="centered-heading">Day</th>
                                                <th className="centered-heading">Open</th>
                                                <th className="centered-heading">Close</th>
                                                <th className="centered-heading">Closed?</th>
                                                <th className="centered-heading">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.scheduleHalalla && this.state.scheduleHalalla.map((item, index) => {
                                                //console.log("Schedule Map----",item);
                                                return (
                                                    <tr key={'key-schedule-table-' + index}>
                                                        <td className="centered-data">{item.day}</td>
                                                        <td className="centered-data">{item.startTime !== undefined && item.startTime !== "" ? item.startTime : "-"}</td>
                                                        <td className="centered-data">{item.endTime !== undefined && item.endTime !== "" ? item.endTime : "-"}</td>
                                                        <td className="centered-data">{item.isClosed === true ? "Closed" : "-"}</td>
                                                        <td className="centered-data delete-icon">
                                                            <FaTrash
                                                                onClick={() => this.handleDeleteButtonClick(index)}
                                                                size={20}
                                                            />
                                                        </td>
                                                    </tr>);
                                            })}
                                            </tbody>
                                        </table>
                                        {/*</Col>*/}


                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() => {
                                                    this.props.hide();
                                                }}
                                            >
                                                Back
                                            </button>

                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() => this.SaveSchedule()}
                                                disabled={this.state.loader}
                                            >
                                                {this.state.loader ? "Saving..." : "Save"}
                                                <Vortex
                                                    loading={this.state.loader}
                                                    visible={this.state.loader}
                                                    height="40"
                                                    ariaLabel="vortex-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="vortex-wrapper"
                                                    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                                                />
                                            </button>
                                        </div>

                                        <div className="clearfix"></div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            );
        }
    }
}

export default schedule;
