import React from "react";
import "../../../views/Style.css";
import { Card, Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import { properties } from "../../../properties.js";
import { SLoader } from "../../../components/CommonComponents";
import Editor from "./../Editor";
import Checkbox from "react-custom-checkbox";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { FaBuilding } from "react-icons/fa";
import * as Icon from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export class chef extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chefId: props.id,
      loading: false,
      halallas: [],
      loader: false,
    };
  }

  async loadChef() {
    try {
      this.setState({ loading: true });
      const chef = await service.loadChef(this.state.chefId);
      console.log("Chef Add/Edit---------", chef);
      this.setState({ ...chef });
    } catch (err) {
      console.log(err);
    }
    this.setState({ loading: false });
  }

  async saveChef() {
    try {
      this.setState({ loader: true });
      const chef = {
        _id: this.state.chefId,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        phone: this.state.phone,
        description: this.state.description,
        active: this.state.active,
      };
      await service.saveChef(this.state.chefId, chef);
      console.log("Save Chef---------", chef);
      NotificationManager.success("Chef Data Saved Successfully", "Saved");
      this.setState({ loader: false });
      this.props.hide();
    } catch (err) {
      console.log("Error----------", err);
    }
    this.setState({ loading: false });
  }

  componentDidMount() {
    this.loadChef();
  }

  render() {
    if (this.state.loading) {
      return <SLoader />;
    } else {
      var context = this;
      return (
        <>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <div className="form-header">
                      <span>Chef Form</span>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="5" className="payment">
                        <Form.Group>
                          <label>First Name</label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FaBuilding size={20} />
                            </InputGroup.Text>
                            <Form.Control
                              as="input"
                              value={this.state.firstName}
                              onChange={(e) => {
                                this.setState({ firstName: e.target.value });
                              }}
                            ></Form.Control>
                          </InputGroup>
                        </Form.Group>
                      </Col>

                      <Col md="5" className="payment">
                        <Form.Group>
                          <label>Last Name</label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FaBuilding size={20} />
                            </InputGroup.Text>
                            <Form.Control
                              as="input"
                              value={this.state.lastName}
                              onChange={(e) => {
                                this.setState({ lastName: e.target.value });
                              }}
                            ></Form.Control>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="5" className="payment">
                        <Form.Group>
                          <label>Email</label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FaBuilding size={20} />
                            </InputGroup.Text>
                            <Form.Control
                              as="input"
                              value={this.state.email}
                              onChange={(e) => {
                                this.setState({ email: e.target.value });
                              }}
                            ></Form.Control>
                          </InputGroup>
                        </Form.Group>
                      </Col>

                      <Col md="5" className="payment">
                        <Form.Group>
                          <label>Phone</label>
                          <InputGroup>
                            <PhoneInput
                              id="inputPhone"
                              country={"pk"}
                              value={this.state.phone}
                              onChange={(phone) => this.setState({ phone })}
                              enableSearch
                              inputStyle={{
                                width: "100%",
                                paddingLeft: "58px", // Adjust padding to fit the flag inside the border
                                borderRadius: "4px",
                                border: "1px solid #ced4da",
                                height: 42,
                              }}
                              buttonStyle={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                height: "100%",
                                borderRight: "1px solid #ced4da",
                                borderRadius: 5,
                                backgroundColor: "#e9ecef",
                                width: "9.5%",
                              }}
                            />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/*<Row>
                      <Col md="10" style={{ marginTop: "15px" }}>
                        <Form.Group>
                          <label>{properties.product.description}</label>
                          <Editor
                            value={this.state.description}
                            onChange={(val) => {
                              this.setState({ description: val });
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>*/}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        //justifyContent: "center",
                        marginTop: 20,
                        marginLeft: 4,
                      }}
                    >
                      <Checkbox
                        onChange={(activated, event) => {
                          this.setState({ active: activated });
                        }}
                        checked={this.state.active}
                        value={this.state.active}
                        icon={
                          <div
                            style={{
                              backgroundColor: "#29427d",
                            }}
                          >
                            <Icon.FiCheck color="#b82e3a" size={20} />
                          </div>
                        }
                        borderColor="gray"
                        // borderWidth={0}
                        borderRadius={20}
                        style={{ overflow: "hidden" }}
                        size={20}
                        label="Active"
                      />
                    </div>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        style={{ marginTop: 30 }}
                        className="saveProductBtn"
                        onClick={() => this.props.hide()}
                      >
                        Back
                      </button>

                      <button
                        style={{ marginTop: 30 }}
                        className="saveProductBtn"
                        onClick={() => this.saveChef()}
                      >
                        Save
                      </button>
                    </div>

                    <div className="clearfix"></div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <NotificationContainer />
          </Container>
        </>
      );
    }
  }
}

export default chef;
