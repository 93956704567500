/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import * as service from "../../services/Service.js";

function Sidebar({ color, image, routes }) {
    const location = useLocation();
    const [role, setRole] = React.useState('');

    const activeRoute = (routeName) => {
        return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    const loadAll = async () => {
        const loginInfo = await service.getLogin();
        setRole(loginInfo?.role ? loginInfo.role : '');
    }

    React.useEffect(() => {
        loadAll();
    }, []); // Added an empty dependency array to ensure this effect runs only once

    return (
        <div className="sidebar" data-image={image} data-color={color}>
            <div
                className="sidebar-background"
                style={{
                    backgroundImage: "url(" + image + ")"
                }}
            />
            <div className="sidebar-wrapper">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginTop: 20 }} className="logo-img">
                        <img src={require("../../assets/img/logo-red.png")} alt="HalallaOd" />
                    </div>
                </div>
                <div className="logo">
                </div>
                <Nav>
                    {routes.map((prop, key) => {
                        if (!prop.redirect) {
                            if (role === 'SuperAdmin') {
                                if (prop.children) {
                                    return (
                                        <li
                                            className={
                                                prop.upgrade
                                                    ? "nav-link active active-pro"
                                                    : ""
                                            }
                                            key={'key-admin-side-nav-' + key}
                                        >
                                            <NavLink
                                                to="#"
                                                className="nav-link"
                                                activeClassName="active"
                                                style={{ width: "95%", margin: 0, marginLeft: 5, padding: 5 }}
                                            >
                                                {typeof prop.icon === 'string' && (prop.icon.startsWith("/") || prop.icon.startsWith("data:image")) ? (
                                                    <img src={prop.icon} alt={prop.name} style={{ width: "32px", height: "32px", marginRight: '5px' }} />
                                                ) : (
                                                    <i className={prop.icon} />
                                                )}
                                                <p>{prop.name}</p>

                                                {prop.children.map((item, index) => {
                                                    return (
                                                        <li
                                                            className={
                                                                prop.upgrade
                                                                    ? "nav-link active active-pro"
                                                                    : activeRoute(item.path)
                                                            }
                                                            key={'key-admin-side-nav-' + key + '-child-' + index}
                                                        >
                                                            <NavLink
                                                                to={item.path}
                                                                className="nav-link"
                                                                activeClassName="active"
                                                                style={{ width: "95%", paddingLeft: 0, margin: 0, marginLeft: 50, padding: 5 }}
                                                            >
                                                                {/**  <i className={item.icon}/> **/}
                                                                <p>{item.name}</p>
                                                            </NavLink>
                                                        </li>
                                                    );
                                                })}
                                            </NavLink>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li
                                            className={
                                                prop.upgrade
                                                    ? "nav-link active active-pro"
                                                    : activeRoute(prop.path)
                                            }
                                            key={'key-admin-side-nav-' + key}
                                        >
                                            <NavLink
                                                to={prop.path}
                                                className="nav-link"
                                                activeClassName="active"
                                                style={{ width: "95%", margin: 0, marginLeft: 5, padding: 5 }}
                                            >
                                                {typeof prop.icon === 'string' && (prop.icon.startsWith("/") || prop.icon.startsWith("data:image")) ? (
                                                    <img src={prop.icon} alt={prop.name} style={{ width: "26px", height: "26px", marginRight: '8px' }} />
                                                ) : (
                                                    <i className={prop.icon} />
                                                )}
                                                <p>{prop.name}</p>
                                            </NavLink>
                                        </li>
                                    );
                                }
                            }
                        }

                        return null;
                    })}
                </Nav>
            </div>
        </div>
    );
}

export default Sidebar;
