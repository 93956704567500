import React from "react";
import "../../../views/Style.css";
import {Button, Col, Container, Dropdown, Modal, Nav, Row,} from "react-bootstrap";
import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {properties} from "../../../properties.js";
import {FaUserEdit} from "react-icons/fa";
import BlogForm from "./Blog";
import Comments from "./Comments"
import Photos from "./photos"

export class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            criteria: {page: 1, size: 10},
            showBlogForm: false,
            showPhotos: false,
            showComments: false,
            focusedInput: null,
            showDeleteModal: false,
        };
        this.hideBlogForm = this.hideBlogForm.bind(this);
        this.hidePhotos = this.hidePhotos.bind(this);
        this.hideComments = this.hideComments.bind(this);

    }


    componentDidMount() {
        this.loadBlogs(this.state.criteria)

    }

    loadPagination(criteria) {

        let paginationViews = undefined;

        if (criteria?.totalPages && criteria.totalPages > 1) {

            const subViews = [];
            let size = criteria.size;
            const count = criteria.totalPages > 10 ?
                (window.innerWidth < 700 ? (criteria.page + 4) : (criteria.page + 9))
                : criteria.totalPages;

            for (let i = criteria.page; i <= count; i++) {

                let page = i;
                console.log('page-------------', page);

                if (page === criteria.page) {
                    subViews.push(
                        <li className="page-item active">
                            <a className="page-link" href="#" onClick={() => {
                                this.loadBlogs({page, size})
                            }}>
                                {page}<span className="sr-only">(current)</span>
                            </a>.
                        </li>
                    )
                } else {
                    subViews.push(<li className="page-item">
                            <a className="page-link" href="#" onClick={() => {
                                this.loadBlogs({page, size})
                            }}>
                                {page}<span className="sr-only">(current)</span>
                            </a>
                        </li>
                    )
                }


            }

            paginationViews = (
                <div key={'key-admin-pagination-halalla-100'}
                     style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                    <nav aria-label="...">
                        <ul className="pagination">
                            <li className={'page-item ' + (criteria.page > 1 ? '' : 'disabled')}>
                                <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => {
                                        if (criteria.page > 1) {
                                            this.loadBlogs({page: criteria.page - 1, size});
                                        }
                                    }}
                                >
                                    Previous
                                < /a>
                            </li>
                            {
                                subViews
                            }
                            <li className={'page-item ' + (criteria.page < criteria.totalPages ? '' : 'disabled')}>
                                <a className="page-link" href="#" onClick={() => {
                                    if (criteria.page > 1) {
                                        this.loadBlogs({page: criteria.page + 1, size});
                                    }
                                }}>
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>)


            console.log(paginationViews);
        }


        this.setState({paginationViews});

    }

    async loadBlogs(criteria) {
        try {
            this.setState({loading: true});
            criteria = await service.loadBlogs(criteria);
            const blogs = criteria.blogs;
            console.log("Blogs---------", blogs);
            this.setState({criteria, blogs});
            this.loadPagination(criteria);
        } catch (err) {
            console.log(err);
        }
        this.setState({loading: false});
    }



    showEdit(i) {
        const blog = this.state.blogs[i];
        this.setState({
            showBlogForm: true,
            selectedBlogId: blog._id
        });
    }

    showComments(i) {
        const blog = this.state.blogs[i];
        this.setState({
            showComments: true,
            selectedBlog: blog
        });
    }

    showPhotos(i) {
        const photo = this.state.blogs[i];
        this.setState({
            showPhotos: true,
            selectedBlog: photo
        });
    }

    showDelete(i) {
        const blog = this.state.blogs[i];
        this.setState({
            showDeleteModal: true,
            selectedBlogId: blog._id
        });
    }


    async hideBlogForm() {
        this.setState({
            loading: false, showBlogForm: false, selectedBlogId: undefined
        });
        this.loadBlogs();
    }

    async hidePhotos() {
        this.setState({
            loading: false, showPhotos: false, selectedBlog: undefined
        });
    }

    async hideComments() {
        this.setState({
            loading: false, showComments: false, selectedBlog: undefined
        });
    }

    renderBlogFormScreen() {
        return <BlogForm id={this.state.selectedBlogId} hide={this.hideBlogForm}/>;
    }

    renderPhotosScreen() {
        return <Photos blog={this.state.selectedBlog} hide={this.hidePhotos}/>;
    }

    renderCommentsScreen() {
        return <Comments blog={this.state.selectedBlog} hide={this.hideComments}/>;
    }

    handleInputFocus = (inputName) => {
        this.setState({focusedInput: inputName});
    };

    handleInputBlur = () => {
        this.setState({focusedInput: null});
    };


    renderDeleteModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showDeleteModal}
                onHide={() => {}}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <i style={{marginRight: 10}} class="fa fa-trash"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do you want to delete this blog</span>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => this.setState({
                            showDeleteModal: false,
                        })}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={async () => {
                            try {
                                await service.deleteBlog({_id: this.state.selectedBlogId});
                                this.loadBlogs(this.state.criteria)
                            } catch (err) {
                                console.log(err)
                            }
                            this.setState({
                                showDeleteModal: false, selectedChefId: undefined
                            })
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        );
    }



    render() {

        if (this.state.showBlogForm) {
            return this.renderBlogFormScreen();
        } else if (this.state.showPhotos) {
            return this.renderPhotosScreen();
        } else if (this.state.showComments) {
            return this.renderCommentsScreen();
        } else {
            var context = this;
            return <>
                {this.renderDeleteModal()}

                <Container fluid>
                    <Row
                        style={{
                            display: "flex", flexDirection: "row", marginBottom: 20,
                        }}
                    >
                        <Col md="6">
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <label className="main-heading">
                                    Blogs
                                </label>
                            </div>
                        </Col>
                        <Col>
                            <div
                                className="search-container"
                                style={{
                                    border: this.state.focusedInput === 'search' ? '2px solid #b82e3a' : '',
                                    borderRadius: 6
                                }}
                            >
                                <input
                                    autoComplete="off"
                                    type='search'
                                    name="search"
                                    value={this.state.search}
                                    placeholder="Search Blog"
                                    onChange={(e) => {
                                        const search = e.target.value;
                                        const criteria = this.state.criteria;
                                        criteria.search = search;
                                        this.setState({criteria, search});
                                        this.loadBlogs(criteria);
                                    }}
                                    className="search-input"
                                    onFocus={() => this.handleInputFocus('search')}
                                    onBlur={this.handleInputBlur}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            alert(1)
                                        }
                                    }}
                                />
                                <i style={{
                                    color: this.state.focusedInput === 'search' ? '#b82e3a' : '',
                                }}
                                   className='fa fa-search'
                                   id="search-icon"
                                ></i>

                            </div>
                        </Col>
                        <div className="d-none d-md-block"> {/* Applies only to screens medium-sized and larger */}
                            <Col>
                                <div style={{display: "flex", justifyContent: "left"}}>
                                    <button
                                        className="add-btn"
                                        type="submit"
                                        onClick={() => {
                                            this.setState({
                                                showBlogForm: true,
                                                active: true
                                            });
                                        }}
                                    >
                                        Add Blog
                                        <i className="add-btn-icon fa fa-plus-circle"></i>{" "}
                                    </button>
                                </div>
                            </Col>
                        </div>

                        {/* Conditionally render the button only in mobile view */}
                        <div className="d-md-none"> {/* Applies only to screens smaller than md (medium) */}
                            <Col>
                                <div style={{display: "flex", justifyContent: "left"}}>
                                    <button
                                        className="add-btn-mbl"
                                        type="submit"
                                        onClick={() => {
                                            this.setState({
                                                showHalalla: true,
                                                active: true
                                            });
                                        }}
                                    >
                                        <i className="add-btn-icon-mbl fa fa-plus-circle"></i>{" "}
                                    </button>
                                </div>
                            </Col>
                        </div>
                    </Row>

                    <Row>
                        <Col md="12">
                            <table className="custom-table">
                                <thead>
                                <tr>
                                    <th className="centered-heading">S.No</th>
                                    <th className="centered-heading">Blog Title</th>
                                    <th className="centered-heading">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.blogs &&
                                    this.state.blogs.map((item, index) => {
                                        console.log("---LIST------", item);
                                        return (
                                            <tr key={'key-halallas-table-' + index}>
                                                <td className="centered-data">{index + 1}</td>
                                                <td className="centered-data">{item.title}</td>
                                                <td className="centered-data">
                                                    <Dropdown style={{
                                                        justifyContent:'center',
                                                        display:'flex'
                                                    }}>
                                                        <Dropdown.Toggle
                                                            as={Nav.Link}
                                                            data-toggle="dropdown"
                                                            id="dropdown-67443507"
                                                            variant="default"
                                                            className="centered-data"
                                                            style={{
                                                                color: "#000",
                                                        }}
                                                        >
                                                            <label>
                                                                {properties.commonLabel.tableHeaderAction}
                                                            </label>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={context.showEdit.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Edit Form
                                                            </Dropdown.Item>

                                                            <Dropdown.Item
                                                                onClick={context.showComments.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Comments
                                                            </Dropdown.Item>

                                                            <Dropdown.Item
                                                                onClick={context.showPhotos.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Photo
                                                            </Dropdown.Item>


                                                            <Dropdown.Item
                                                                onClick={context.showDelete.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                Delete
                                                            </Dropdown.Item>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {this.state.paginationViews}


                        </Col>
                    </Row>
                </Container>
            </>
        }
    }
}

export default Index;
