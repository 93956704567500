import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Dropdown,
} from "react-bootstrap";

import * as service from "../../services/Service.js";
import { properties } from '../../properties.js';
const avatarUrl = require("../../assets/img/123.jpeg");
import "react-image-crop/dist/ReactCrop.css";

export class HalallaGalleryOld extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cropImage: undefined,
      showImageCropper: false,
      imagePath : props?.imagePath,
      crop: {
        unit: "px",
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      },
    };
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);

      // this.imageRef = e.target.files[0];
      // alert(this.imageRef )
    }
  };

  onImageLoaded = (e) => {
    this.imageRef = e.target;
  };

  onCropComplete = (crop) => {
    this.setState({ crop });
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && this.state.crop?.width && this.state.crop?.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        this.state.crop,
        "newFile.jpeg"
      );
      this.imageRef = undefined;
      this.setState({ croppedImageUrl, src: undefined });
    }
  }

  getFilename(fullPath) {
    return fullPath.replace(/^.*[\\\/]/, "");
  }

  async saveImage() {
    const response = await fetch(this.state.croppedImageUrl);
    const blob = await response.blob();
    /*const file = new File([data], this.getFilename(this.state.croppedImageUrl), {
      type: data.type,
    });*/

    // var blob = items[i].getAsFile();

    var context = this;

    // 1. Create a FileReader instance
    const reader = new FileReader();
    // 2. Add a handler for the 'onload' event
    reader.onload = (e) => {
      // 5. Get the result when the 'onload' event is triggered.
      const base64data = reader.result;
      console.log({ base64data });
      context.props.hide(base64data)
    };
    // 3. Add a handler for the 'onerror' event
    reader.onerror = () => {
      console.log("error");
      context.props.hide();
    };
    // 4. Call 'readAsDataURL' method
    reader.readAsDataURL(blob);
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  renderMain() {
    return (
      <Card className="strpied-tabled-with-hover" style={{ padding: 20 }}>
        <Card.Header style={{ display: "flex" }}>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
          <div
            style={{ display: "flex", width: "100%", justifyContent: "right" }}
          >
            <button
              className="btn"
              onClick={() => {
                this.props.hide();
              }}
            >
              {properties.commonLabel.cancelBtnLabel}
            </button>

            {this.state.croppedImageUrl && (
              <button className="btn" onClick={() => this.saveImage()}>
                {properties.commonLabel.saveBtnLabel}
              </button>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {!this.state.croppedImageUrl && this.state.imagePath && (
            <>
              <img src={this.state.imagePath} />
            </>
          )}
          {this.state.croppedImageUrl && (
            <>
              <img src={this.state.croppedImageUrl} />
            </>
          )}
        </Card.Body>
      </Card>
    );
  }

  renderImageCrop() {
    return (
      <Card className="strpied-tabled-with-hover">
        <Card.Header style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{ marginTop: 30 }}
            className="saveAgencyBtn"
            onClick={() =>
              this.setState({
                src: undefined,
              })
            }
          >
            {properties.commonLabel.cancelBtnLabel}
          </button>

          <button
            style={{ marginTop: 30 }}
            className="saveAgencyBtn"
            onClick={() => this.makeClientCrop()}
          >
            {properties.commonLabel.nextBtnLabel}
          </button>
        </Card.Header>
        <Card.Body>
          {this.state.src && (
            <ReactCrop
              aspect={16 / 16}
              minWidth={100}
              minHeight={100}
              crop={this.state.crop}
              onChange={this.onCropChange}
              onComplete={this.onCropComplete}
            >
              <img src={this.state.src} onLoad={this.onImageLoaded} />
            </ReactCrop>
          )}
        </Card.Body>
      </Card>
    );
  }

  render() {
    return (
      <>
        <Container fluid style={{ padding: 10 }}>
          <Row>
            <Col md="12">
              <p style={{ fontSize: 20, marginLeft: 5 }}>Gallery</p>
              {this.state.src ? this.renderImageCrop() : this.renderMain()}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default HalallaGalleryOld;
