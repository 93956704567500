import React from "react";
import "../../../views/Style.css";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Modal,
    Form,
    Dropdown,
    InputGroup,
} from "react-bootstrap";

import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import { HiMiniBarsArrowDown } from "react-icons/hi2";
import { MdOutlineMail  } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { TbWorldWww } from "react-icons/tb";
import { MdOutlineTitle } from "react-icons/md";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import {properties} from "../../../properties.js";
import {SLoader} from "../../../components/CommonComponents";
import {WithContext as ReactTags} from 'react-tag-input';
import Editor from "./../Editor";
import GoogleMapReact from 'google-map-react';
import HalallaGalleryOld from "../HalallaGalleryOld";
import {
    loadAlcoholAvailability, loadCategories,
    loadHalalAuthentication,
    loadHalalAuthority,
    loadHalalOfferings, loadInternetConnectivity, loadPrayersFacilities,
    loadPriceRange, loadReservationAvailability, loadRestroomFacilities
} from "../../../services/Service.js";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import {Vortex} from "react-loader-spinner";



export class halalla extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            halallaId: props.id,
            loading: false,
            halallas: [],
            loader: false
        };
    }


    async loadHalalla(){
        try {
            this.setState({loading : true})
            const halalla = await service.loadHalalla(this.state.halallaId);
            console.log("Halalla Add/Edit---------",halalla);
            this.setState({...halalla});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async saveHalalla() {
        try {
            this.setState({ loader: true });
            const halalla = {
                _id : this.state.halallaId,
                category : this.state.category,
                active : this.state.active,
                title: this.state.title,
                halalSummary: this.state.halalSummary,
                takeAway: this.state.takeAway,
                shishaHookah : this.state.shishaHookah,
                doIt : this.state.doIt,
                organicOptions : this.state.organicOptions,
                cateringAvailable : this.state.cateringAvailable,
                homeDelivery : this.state.homeDelivery,
                driveThru : this.state.driveThru,
                parkingAvailable : this.state.parkingAvailable,
                massTransitAccessible : this.state.massTransitAccessible,
                outdoorSeating : this.state.outdoorSeating,
                childFriendlyPremises : this.state.childFriendlyPremises,
                wheelChairAccessible : this.state.wheelChairAccessible,
                cash : this.state.cash,
                visa : this.state.visa,
                masterCard : this.state.masterCard,
                amex : this.state.amex,
                atm : this.state.atm,
                discover : this.state.discover,
                email : this.state.email,
                phone : this.state.phone,
                website : this.state.website,
                orderUrl: this.state.orderUrl,
                status : this.state.status,
                description: this.state.description,
                alcoholAvailability : this.state.alcoholAvailability,
                prayersFacilities : this.state.prayersFacilities,
                halalOfferings : this.state.halalOfferings,
                halalAuthentication : this.state.halalAuthentication,
                halalAuthority : this.state.halalAuthority,
                reservationAvailability : this.state.reservationAvailability,
                restroomFacilities : this.state.restroomFacilities,
                internetConnectivity : this.state.internetConnectivity,
                priceRange : this.state.priceRange,
        };
            await service.saveHalalla(this.state.halallaId,halalla);
            console.log("Save Halalla---------",halalla);
            NotificationManager.success('Halalla Saved Successfully', 'Saved');
            this.setState({ loader: false });
            this.props.hide();
        } catch (err) {
            console.log("Error----------",err);
        }
        this.setState({ loading: false });
    }

    async loadCategories(){
        try {
            this.setState({loading : true})
            const categoriesOptions = await service.loadCategories();
            console.log("Categories---------",categoriesOptions);
            this.setState({categoriesOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadPriceRange(){
        try {
            this.setState({loading : true})
            const priceRangeOptions = await service.loadPriceRange();
            console.log("Price Range---------",priceRangeOptions);
            this.setState({priceRangeOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadStatus(){
        try {
            this.setState({loading : true})
            const statusOptions = await service.loadStatus();
            console.log("Price Range---------",statusOptions);
            this.setState({statusOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadHalalAuthority(){
        try {
            this.setState({loading : true})
            const halalAuthorityOptions = await service.loadHalalAuthority();
            this.setState({halalAuthorityOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadHalalAuthentication(){
        try {
            this.setState({loading : true})
            const halalAuthenticationOptions = await service.loadHalalAuthentication();
            this.setState({halalAuthenticationOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadHalalOfferings(){
        try {
            this.setState({loading : true})
            const halalOfferingsOptions = await service.loadHalalOfferings();
            this.setState({halalOfferingsOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadPrayersFacilities(){
        try {
            this.setState({loading : true})
            const prayersFacilitiesOptions = await service.loadPrayersFacilities();
            this.setState({prayersFacilitiesOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadAlcoholAvailability(){
        try {
            this.setState({loading : true})
            const alcoholAvailabilityOptions = await service.loadAlcoholAvailability();
            this.setState({alcoholAvailabilityOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadReservationAvailability(){
        try {
            this.setState({loading : true})
            const reservationAvailabilityOptions = await service.loadReservationAvailability();
            this.setState({reservationAvailabilityOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadRestroomFacilities(){
        try {
            this.setState({loading : true})
            const restroomFacilitiesOptions = await service.loadRestroomFacilities();
            this.setState({restroomFacilitiesOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }

    async loadInternetConnectivity(){
        try {
            this.setState({loading : true})
            const internetConnectivityOptions = await service.loadInternetConnectivity();
            this.setState({internetConnectivityOptions});
        }
        catch (err) {
            console.log(err)
        }
        this.setState({loading : false})

    }



    componentDidMount() {
        this.loadHalalla();
        this.loadPriceRange();
        this.loadStatus();
        this.loadHalalAuthority();
        this.loadHalalAuthentication();
        this.loadHalalOfferings();
        this.loadPrayersFacilities();
        this.loadAlcoholAvailability();
        this.loadReservationAvailability();
        this.loadRestroomFacilities();
        this.loadInternetConnectivity();
        this.loadCategories();
    }


    render() {
        if (this.state.loading) {
            return <SLoader/>;
        } else {
            var context = this;
            return (
                <>
                    <Container fluid>
                        <NotificationContainer />
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Header>
                                        <Card.Title as="h4">
                                            Halalla Information
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Category</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.category}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                console.log("selectedValue----",selectedValue);
                                                                this.setState({ category: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.categoriesOptions && this.state.categoriesOptions.map((item, index) => {
                                                                return (
                                                                        <option key={item._id} value={item._id}>
                                                                        {item.name}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Current Status</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.status}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ status: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.statusOptions && this.state.statusOptions.map((item, index) => {
                                                                return (
                                                                <option key={item.value} value={item.value}>
                                                                    {item.label}
                                                                </option>
                                                        );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Title</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <MdOutlineTitle size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            value={this.state.title ? this.state.title : this.state.zTitle}
                                                            placeholder= "Halalla Title"
                                                            type="text"
                                                            onChange={(e) => {
                                                                this.setState({ title: e.target.value });
                                                            }}
                                                        ></Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="12" style={{marginBottom: 20}}>
                                                <Form.Group>
                                                    <label>{properties.product.description}</label>
                                                    <Editor value={this.state.description}
                                                            onChange={val => {
                                                        this.setState({description: val});
                                                    }}/>

                                                </Form.Group>
                                            </Col>
                                            </Row>
                                        <Row style={{marginBottom:10}}>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Email</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <MdOutlineMail  size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            value={this.state.email}
                                                            placeholder= "Email"
                                                            type="text"
                                                            onChange={(e) => {
                                                                this.setState({ email: e.target.value });
                                                            }}
                                                        ></Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Phone</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <BsTelephone size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            value={this.state.phone}
                                                            placeholder= "Phone"
                                                            type="text"
                                                            onChange={(e) => {
                                                                this.setState({ phone: e.target.value });
                                                            }}
                                                        ></Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            </Row>
                                        <Row style={{marginBottom:20}}>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Website</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <TbWorldWww size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            value={this.state.website}
                                                            placeholder= "Website"
                                                            type="text"
                                                            onChange={(e) => {
                                                                this.setState({ website: e.target.value });
                                                            }}
                                                        ></Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="4">
                                                <Form.Group>
                                                    <label>Direct Order URL</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <TbWorldWww size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            value={this.state.orderUrl}
                                                            placeholder= "Order URL"
                                                            type="text"
                                                            onChange={(e) => {
                                                                this.setState({ orderUrl: e.target.value });
                                                            }}
                                                        ></Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom:10}}>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Halal Authority</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.halalAuthority}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ halalAuthority: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.halalAuthorityOptions && this.state.halalAuthorityOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="12" style={{marginBottom: 20}}>
                                                <Form.Group>
                                                    <label>Halal Description</label>
                                                    <Editor value={this.state.halalSummary ? this.state.halalSummary : this.state.zSummary}
                                                            onChange={val => {
                                                                this.setState({halalSummary: val});
                                                            }}/>

                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Halal Authentication</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.halalAuthentication}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ halalAuthentication: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.halalAuthenticationOptions && this.state.halalAuthenticationOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Halal Offerings</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.halalOfferings}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ halalOfferings: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.halalOfferingsOptions && this.state.halalOfferingsOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Prayer Facilities</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.prayersFacilities}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ prayersFacilities: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.prayersFacilitiesOptions && this.state.prayersFacilitiesOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Alcohol Availability</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.alcoholAvailability}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ alcoholAvailability: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.alcoholAvailabilityOptions && this.state.alcoholAvailabilityOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row style={{marginBottom:40}}>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Reservation Availability</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.reservationAvailability}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ reservationAvailability: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.reservationAvailabilityOptions && this.state.reservationAvailabilityOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Restroom Facilities</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.restroomFacilities}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ restroomFacilities: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.restroomFacilitiesOptions && this.state.restroomFacilitiesOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md="6">
                                                <Form.Group>
                                                    <label>Internet Connectivity</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.internetConnectivity}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ internetConnectivity: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.internetConnectivityOptions && this.state.internetConnectivityOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({takeAway: activated});
                                                    }}
                                                    checked={this.state.takeAway}
                                                    value={this.state.takeAway}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_to_go.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Takeaway/to-go
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({cateringAvailable: activated});
                                                    }}
                                                    checked={this.state.cateringAvailable}
                                                    value={this.state.cateringAvailable}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_catering.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Catering available
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({massTransitAccessible: activated});
                                                    }}
                                                    checked={this.state.massTransitAccessible}
                                                    value={this.state.massTransitAccessible}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_transit.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Mass transit accessible
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({shishaHookah: activated});
                                                    }}
                                                    checked={this.state.shishaHookah}
                                                    value={this.state.shishaHookah}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_shisha.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Shisha/hookah
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({homeDelivery: activated});
                                                    }}
                                                    checked={this.state.homeDelivery}
                                                    value={this.state.homeDelivery}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_delivery.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Home delivery
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({outdoorSeating: activated});
                                                    }}
                                                    checked={this.state.outdoorSeating}
                                                    value={this.state.outdoorSeating}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_outdoor.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Outdoor seating
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({doIt: activated});
                                                    }}
                                                    checked={this.state.doIt}
                                                    value={this.state.doIt}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_diy.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Do it yourself" halal
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({driveThru: activated});
                                                    }}
                                                    checked={this.state.driveThru}
                                                    value={this.state.driveThru}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_drive_thru.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Drive-thru service
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({childFriendlyPremises: activated});
                                                    }}
                                                    checked={this.state.childFriendlyPremises}
                                                    value={this.state.childFriendlyPremises}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_children.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Child-friendly premises
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({organicOptions: activated});
                                                    }}
                                                    checked={this.state.organicOptions}
                                                    value={this.state.organicOptions}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_organic.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Organic options
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({parkingAvailable: activated});
                                                    }}
                                                    checked={this.state.parkingAvailable}
                                                    value={this.state.parkingAvailable}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_parking.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Parking available
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({wheelChairAccessible: activated});
                                                    }}
                                                    checked={this.state.wheelChairAccessible}
                                                    value={this.state.wheelChairAccessible}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/logo-red.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_access.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Wheelchair accessible
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="6" className="payment" style={{marginTop:20}}>
                                                <span>Payment Details</span>

                                                <Form.Group>
                                                    <label>Price Range</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <HiMiniBarsArrowDown size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="select"
                                                            value={this.state.priceRange}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                this.setState({ priceRange: selectedValue });
                                                            }}
                                                        >
                                                            {this.state.priceRangeOptions && this.state.priceRangeOptions.map((item, index) => {
                                                                return (
                                                                    <option key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                        </Row>


                                        <Row style={{marginTop: 20}}>
                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({cash: activated});
                                                    }}
                                                    checked={this.state.cash}
                                                    value={this.state.cash}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/icon_cash.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_cash.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Cash
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({visa: activated});
                                                    }}
                                                    checked={this.state.visa}
                                                    value={this.state.visa}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/icon_visa.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_visa.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Visa
                                                        </>
                                                    }
                                                />
                                            </Col>

                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({amex: activated});
                                                    }}
                                                    checked={this.state.amex}
                                                    value={this.state.amex}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/icon_amex.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_amex.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            AMEX
                                                        </>
                                                    }
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({masterCard: activated});
                                                    }}
                                                    checked={this.state.masterCard}
                                                    value={this.state.masterCard}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/icon_mc.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_mc.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            MasterCard
                                                        </>
                                                    }
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({atm: activated});
                                                    }}
                                                    checked={this.state.atm}
                                                    value={this.state.atm}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/icon_atm.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_atm.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            ATM/Debit
                                                        </>
                                                    }
                                                />
                                            </Col>
                                            <Col md="3">
                                                <Checkbox
                                                    onChange={(activated, event) => {
                                                        this.setState({discover: activated});
                                                    }}
                                                    checked={this.state.discover}
                                                    value={this.state.discover}
                                                    icon={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flex: 1,

                                                            }}
                                                        >
                                                            <img
                                                                src={require('../../../assets/img/icon_disc.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginLeft: "3px",
                                                                    width: "10px",
                                                                    height: "10px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                        </div>
                                                    }
                                                    borderColor="gray"
                                                    // borderWidth={0}
                                                    borderRadius={20}
                                                    style={{overflow: "hidden"}}
                                                    size={20}
                                                    label={
                                                        <>
                                                            <img
                                                                src={require('../../../assets/img/icon_disc.png')}
                                                                alt="Image Alt Text"
                                                                style={{
                                                                    marginRight: "10px",
                                                                    width: "20px",
                                                                    height: "20px"
                                                                }} // Adjust the styling as needed
                                                            />
                                                            Discover
                                                        </>
                                                    }
                                                />
                                            </Col>

                                        </Row>


                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                marginTop: 20,
                                            }}
                                        >
                                            <Checkbox
                                                onChange={(activated, event) => {
                                                    this.setState({active: activated});
                                                }}
                                                checked={this.state.active}
                                                value={this.state.active}
                                                icon={
                                                    <div
                                                        style={{
                                                            backgroundColor: "#29427d",
                                                        }}
                                                    >
                                                        <Icon.FiCheck color="#b82e3a" size={20}/>
                                                    </div>
                                                }
                                                borderColor="gray"
                                                // borderWidth={0}
                                                borderRadius={20}
                                                style={{overflow: "hidden"}}
                                                size={20}
                                                label="Active"

                                            />
                                        </div>

                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() => {
                                                    this.props.hide();
                                                }}
                                            >
                                                Back
                                            </button>

                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() => this.saveHalalla()}
                                                disabled={this.state.loader}
                                            >
                                                {this.state.loader ? (this.state.halallaId ? "Updating..." : "Saving...") : (this.state.halallaId ? "Update" : "Save")}
                                                <Vortex
                                                    loading={this.state.loader}
                                                    visible={this.state.loader}
                                                    height="40"
                                                    ariaLabel="vortex-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="vortex-wrapper"
                                                    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                                                />
                                            </button>
                                        </div>

                                        <div className="clearfix"></div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            )
                ;
        }
    }
}

export default halalla;
