import React from "react";
import { Circles } from "react-loader-spinner";

export const SLoader = ({}) => {
  return (
    <Circles
      height="80"
      width="80"
      color="#000000"
      ariaLabel="circles-loading"
      wrapperStyle={{justifyContent: "center", marginTop: 180}}
      wrapperClass=""
      visible={true}
    />
  );
};
