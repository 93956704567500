import React from "react";
import * as service from "../services/Service.js";
import {saveLogin} from "../services/Service.js";
import "../views/Style.css";
import {NotificationContainer,} from "react-notifications";

import "react-notifications/lib/notifications.css";
import {Vortex} from "react-loader-spinner";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            focusedInput: null,
            showPassword: false,
        };
    }

    async login() {
        try {
            /*if (!this.state.email) {
              NotificationManager.info('Enter Email Address');
              this.setState({ loading: false });
              return;
            }
            if (!this.state.password) {
              NotificationManager.info('Empty Password');
              this.setState({ loading: false });
              return;
            }*/
            this.setState({loading: true});
            if (this.state.email && this.state.password) {
                const response = await service.login({
                    email: this.state.email,
                    password: this.state.password,
                });

                if (response?.token) {
                    await saveLogin(response);
                    window.location.href = "/halalla";
                } else {
                    //NotificationManager.error('Login Failed');
                    //NotificationManager.error("Login Failed", "", 1000);
                    toast.error("Login Failed", {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            } else {
                toast.warning("Empty Field Not Allowed", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
            this.setState({loading: false});
        } catch (error) {
            this.setState({loading: false});
            toast.error("Login Failed", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log("--------", error);
        }
    }

    handleInputFocus = (inputName) => {
        this.setState({focusedInput: inputName});
    };

    // Handle input blur events
    handleInputBlur = () => {
        this.setState({focusedInput: null});
    };

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    render() {
        const {focusedInput, showPassword} = this.state;
        const passwordInputType = showPassword ? 'text' : 'password';
        return (
            <div className="login-container">
                <ToastContainer/>
                <NotificationContainer/>
                <div className="background-image"></div>
                <div className="centered-container">
                    <img src={require('../assets/img/logo-red.png')} alt="Logo" className="logo"/>
                    {/*<h2>Login</h2>*/}
                    <form>
                        <div className="form-group">
                            <div
                                className="input-container"
                                style={{border: focusedInput === 'email' ? '2px solid #b82e3a' : '', borderRadius: 6}}
                            >
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    onChange={(e) => {
                                        this.setState({email: e.target.value});
                                    }}
                                    onFocus={() => this.handleInputFocus('email')}
                                    onBlur={this.handleInputBlur}
                                />
                                <i className="fa fa-user" id="user-icon"></i>
                            </div>
                        </div>
                        <div className="form-group">
                            <div
                                className="input-container"
                                style={{
                                    border: focusedInput === 'password' ? '2px solid #b82e3a' : '',
                                    borderRadius: 6
                                }}
                            >
                                <input
                                    type={passwordInputType}
                                    name="password"
                                    placeholder="Password"
                                    onChange={(e) => {
                                        this.setState({password: e.target.value});
                                    }}
                                    onFocus={() => this.handleInputFocus('password')}
                                    onBlur={this.handleInputBlur}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            this.login();
                                        }
                                    }}
                                />
                                <i
                                    className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                    id="user-icon"
                                    onClick={this.togglePasswordVisibility}
                                ></i>
                            </div>
                        </div>

                        <div style={{justifyContent: 'center', display: 'flex', marginTop: 40}}>
                            <button
                                type="submit"
                                className="gauto-theme-btn"
                                onClick={() => {
                                    this.login();
                                }}
                                disabled={this.state.loading}
                            >
                                {this.state.loading ? "Loging In.." : "Login"}
                                <Vortex
                                    loading={this.state.loading}
                                    visible={this.state.loading}
                                    height="40"
                                    ariaLabel="vortex-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="vortex-wrapper"
                                    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;
