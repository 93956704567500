import React from "react";
import "../../../views/Style.css";
import {Button, Card, Col, Container, Form, InputGroup, Modal, Row,} from "react-bootstrap";

import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {properties} from "../../../properties.js";
import {SLoader} from "../../../components/CommonComponents";
import Editor from "./../Editor";
import {NotificationManager,} from "react-notifications";
import {FaBuilding} from "react-icons/fa";

const timings = [
    {value: "00:00:00", label: "12:00 AM"},
    {value: "00:30:00", label: "12:30 AM"},
    {value: "01:00:00", label: "1:00 AM"},
    {value: "01:30:00", label: "1:30 AM"},
    {value: "02:00:00", label: "2:00 AM"},
    {value: "02:30:00", label: "2:30 AM"},
    {value: "03:00:00", label: "3:00 AM"},
    {value: "03:30:00", label: "3:30 AM"},
    {value: "04:00:00", label: "4:00 AM"},
    {value: "04:30:00", label: "4:30 AM"},
    {value: "05:00:00", label: "5:00 AM"},
    {value: "05:30:00", label: "5:30 AM"},
    {value: "06:00:00", label: "6:00 AM"},
    {value: "06:30:00", label: "6:30 AM"},
    {value: "07:00:00", label: "7:00 AM"},
    {value: "07:30:00", label: "7:30 AM"},
    {value: "08:00:00", label: "8:00 AM"},
    {value: "08:30:00", label: "8:30 AM"},
    {value: "09:00:00", label: "9:00 AM"},
    {value: "09:30:00", label: "9:30 AM"},
    {value: "10:00:00", label: "10:00 AM"},
    {value: "10:30:00", label: "10:30 AM"},
    {value: "11:00:00", label: "11:00 AM"},
    {value: "11:30:00", label: "11:30 AM"},
    {value: "12:00:00", label: "12:00 PM"},
    {value: "12:30:00", label: "12:30 PM"},
    {value: "13:00:00", label: "01:00 PM"},
    {value: "13:30:00", label: "01:30 PM"},
    {value: "14:00:00", label: "02:00 PM"},
    {value: "14:30:00", label: "02:30 PM"},
    {value: "15:00:00", label: "03:00 PM"},
    {value: "15:30:00", label: "03:30 PM"},
    {value: "16:00:00", label: "04:00 PM"},
    {value: "16:30:00", label: "04:30 PM"},
    {value: "17:00:00", label: "05:00 PM"},
    {value: "17:30:00", label: "05:30 PM"},
    {value: "18:00:00", label: "06:00 PM"},
    {value: "18:30:00", label: "06:30 PM"},
    {value: "19:00:00", label: "07:00 PM"},
    {value: "19:30:00", label: "07:30 PM"},
    {value: "20:00:00", label: "08:00 PM"},
    {value: "20:30:00", label: "08:30 PM"},
    {value: "21:00:00", label: "09:00 PM"},
    {value: "21:30:00", label: "09:30 PM"},
    {value: "22:00:00", label: "10:00 PM"},
    {value: "22:30:00", label: "10:30 PM"},
    {value: "23:00:00", label: "11:00 PM"},
    {value: "23:30:00", label: "11:30 PM"},
];


export class form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chefId: props.id,
            chefForm: {},
            loading: false,
            loader: false,
            showDeleteModal: false,
            showApproveModal: false,
            mapCenter: {lat: 24.8102339, lng: 67.06021249999999},
            maps: null,
            map: null,
            location:[]
        };
    }


    async loadForm() {
        try {
            this.setState({loading: true})
            const chefForm = await service.editChefForm(this.state.chefId);
            console.log("ChefForms Edit---------", chefForm);
            this.setState({chefForm});
        } catch (err) {
            console.log(err)
        }
        this.setState({loading: false})
    }

    async saveForm() {
        try {
            this.setState({loader: true});
            const approvalForm = {
                _id: this.state.chefForm._id,
                firstName: this.state.chefForm.firstName,
                lastName: this.state.chefForm.lastName,
                description: this.state.chefForm.description,
                phone: this.state.chefForm.phone,
                email: this.state.chefForm.email,
            };
            await service.saveChefForm(approvalForm);
            console.log("Save Halalla---------", approvalForm);
            NotificationManager.success('Halalla Saved Successfully', 'Saved');
            this.setState({loader: false});
            this.props.hide();
        } catch (err) {
            console.log("Error----------", err);
        }
        this.setState({loading: false});
    }


    componentDidMount() {
        this.loadForm();
    }

    renderDeleteModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showDeleteModal}
                onHide={() => {
                }}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <i style={{marginRight: 10}} class="fa fa-trash"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do you want to delete this chef form</span>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => this.setState({
                            showDeleteModal: false,
                        })}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => {
                            this.loadHalallas();
                            this.setState({
                                showDeleteModal: false
                            })
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        );
    }


    render() {
        if (this.state.loading) {
            return <SLoader/>;
        } else {
            var context = this;
            return (
                <>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Header>
                                        <div className="form-header">
                                            <span>Chef Form</span>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md="5" className="payment">

                                                <Form.Group>
                                                    <label>First Name</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <FaBuilding size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="input"
                                                            value={this.state.chefForm.firstName}
                                                            onChange={(e) => {
                                                                const firstName = e.target.value;
                                                                const chefForm = this.state.chefForm;
                                                                chefForm.firstName = firstName;
                                                                this.setState({chefForm});
                                                            }}
                                                        >
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                            <Col md="5" className="payment">

                                                <Form.Group>
                                                    <label>Last Name</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <FaBuilding size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="input"
                                                            value={this.state.chefForm.lastName}
                                                            onChange={(e) => {
                                                                const v = e.target.value;
                                                                const chefForm = this.state.chefForm;
                                                                chefForm.lastName = v;
                                                                this.setState({chefForm});
                                                            }}
                                                        >
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="5" className="payment">

                                                <Form.Group>
                                                    <label>Email</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <FaBuilding size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="input"
                                                            value={this.state.chefForm.email}
                                                            onChange={(e) => {
                                                                const v = e.target.value;
                                                                const chefForm = this.state.chefForm;
                                                                chefForm.email = v;
                                                                this.setState({chefForm});
                                                            }}
                                                        >
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                            <Col md="5" className="payment">

                                                <Form.Group>
                                                    <label>Phone</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <FaBuilding size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="input"
                                                            value={this.state.chefForm.phone}
                                                            onChange={(e) => {
                                                                const v = e.target.value;
                                                                const chefForm = this.state.chefForm;
                                                                chefForm.phone = v;
                                                                this.setState({chefForm});
                                                            }}
                                                        >
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="10" className="payment">

                                                <Form.Group>
                                                    <label>Address</label>
                                                    <InputGroup>
                                                        <InputGroup.Text>
                                                            <FaBuilding size={20}/>
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            as="input"
                                                            value={this.state.chefForm.location?.address || ""}
                                                            onChange={(e) => {
                                                                const v = e.target.value;
                                                                const chefForm = { ...this.state.chefForm };
                                                                chefForm.location = { ...chefForm.location, address: v };
                                                                this.setState({ chefForm });
                                                            }}
                                                        >
                                                        </Form.Control>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md="10" style={{marginTop: '15px'}}>
                                                <Form.Group>
                                                    <label>{properties.product.description}</label>
                                                    <Editor
                                                        value={this.state.chefForm.description}
                                                        onChange={val => {
                                                            const chefForm = this.state.chefForm;
                                                            chefForm.description = val;
                                                            this.setState({chefForm});
                                                        }}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() =>
                                                    this.props.hide()
                                                }
                                            >
                                                Back
                                            </button>


                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() => {
                                                    this.saveForm();
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>

                                        <div className="clearfix"></div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    {this.renderDeleteModal()}
                </>
            );
        }
    }
}

export default form;
