import React from "react";
import "../../../views/Style.css";
// react-bootstrap components
import {Button, Card, Col, Container, Dropdown, Form, InputGroup, Modal, Nav, Row,} from "react-bootstrap";

import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {FaBuilding, FaImages, FaTrash, FaUserEdit} from "react-icons/fa";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import {properties} from "../../../properties.js";
import {SLoader} from "../../../components/CommonComponents";
import GoogleMapReact from 'google-map-react';
import {loadHalallaLocation} from "../../../services/Service.js";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {MagnifyingGlass, Vortex} from 'react-loader-spinner';
import {NotificationManager} from "react-notifications";


export class location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            halalla: props.halalla,
            loading: false,
            maps: null,
            map: null,
            address: '',
            loader: false
        };
    }


    saveLocation = async () => {
        try {
            this.setState({ loader: true });
            const _id = this.state.halalla._id;
            const location = {};
            location.latLng = this.state.latLng;
            location.address = this.state.address;
            location.country = this.state.country;
            location.countryCode = this.state.countryCode;
            location.city = this.state.city;
            location.postalCode = this.state.postalCode;
            await service.saveHalallaLocation({_id, location});
            NotificationManager.success('Location Saved', 'Saved');
            this.setState({ loader: false });
            this.props.hide();

        } catch (err) {
            console.log(err);
        }
    }

    addMarker = (center, map, maps) => {

        try {

            if (map) {

                const marker = this.state.marker;
                if (marker) {
                    marker.setMap(null);
                }

                console.log(marker);

                let icon = window.location.origin + '/assets/images/location.png';
                icon = 'https://halalla-staging.vercel.app/assets/images/location.png';

                let m = new maps.Marker({
                    position: center, draggable: true, icon
                });

                m.addListener('dragend', (event) => {
                    const position = m.position;
                    const latLng = {};
                    latLng.lat = position.lat();
                    latLng.lng = position.lng();
                    this.setState({latLng});
                    console.log(latLng);
                });

                console.log(m);

                m.setMap(map);

                this.setState({marker: m});

                map.setCenter(center);

            }
        } catch (err) {
            console.log(err)
        }

    }


    handleSelect = async (selectedAddress, placeId) => {
        try {

            const results = await geocodeByAddress(selectedAddress);

            const lPostalCode = results[0].address_components.find((component) => {
                return component.types.includes("postal_code");
            });

            let postalCode = '';

            if (lPostalCode?.long_name) {
                postalCode = lPostalCode?.long_name;
            }

            const lCountry = results[0].address_components.find((component) => {
                return component.types.includes("country");
            });


            let country = '';
            if (lCountry && lCountry.long_name) {
                country = lCountry.long_name;
            }

            let countryCode = '';

            if (lCountry && lCountry.short_name) {
                countryCode = lCountry.short_name;
            }


            const lCity = results[0].address_components.find((component) => {
                return component.types.includes('locality');
            });

            let city = '';
            if (lCity && lCity.long_name) {
                city = lCity.long_name;
            }

            const latLng = results[0].geometry.location;

            this.addMarker(latLng, this.state.map, this.state.maps);

            this.setState({address: results[0].formatted_address, latLng, city, postalCode, countryCode, country})

        } catch (error) {
            console.error('Error fetching geolocation data:', error);
        }
    };


    async loadHalallaLocation() {
        try {
            this.setState({loading: true})
            const location = await service.loadHalallaLocation(this.state.halalla._id);
            console.log(location);
            this.setState({...location});
        } catch (err) {
            console.log(err)
        }
        this.setState({loading: false})

    }

    componentDidMount() {
        this.loadHalallaLocation()

    }


    render() {
        const {address} = this.state;

        if (this.state.loading) {
            return <SLoader/>;
        } else {
            var context = this;
            return (
                <>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Header>
                                        <div  className="schedule-header-container">
                                        <Card.Title as="h4" className="schedule-header">
                                            {`Location: ${this.state.halalla.title ? this.state.halalla.title : this.state.halalla.zTitle}`}
                                        </Card.Title>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>

                                        <Row>
                                            <Col className="pr-1" md="6">
                                                <label>Address</label>
                                                <PlacesAutocomplete
                                                    value={address}
                                                    onChange={(value) => this.setState({address: value})}
                                                    onSelect={this.handleSelect}
                                                    googleCallbackName="initPlacesAutocomplete"
                                                    googleCallbackAddress="AIzaSyD1YtfPS6pXCVlWhB1Q-Zse2WTGlV9FIUA"
                                                >
                                                    {({
                                                          getInputProps,
                                                          suggestions,
                                                          getSuggestionItemProps,
                                                          loading
                                                      }) => (
                                                        <div>
                                                            <input {...getInputProps({placeholder: 'Type address'})} />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && (<div style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                }}>
                                                                    <MagnifyingGlass
                                                                        visible={true}
                                                                        height="80"
                                                                        width="80"
                                                                        ariaLabel="MagnifyingGlass-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClass="MagnifyingGlass-wrapper"
                                                                        glassColor='#fff'
                                                                        color='#b82e3a'
                                                                    />
                                                                </div>)}
                                                                {suggestions.map((suggestion) => {
                                                                    const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                                                                    return (<div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                        })}
                                                                    >
                                                                        {suggestion.description}
                                                                    </div>);
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>

                                            </Col>
                                        </Row>


                                        <div className="map-box mt-50" style={{marginTop: 50}}>
                                            <div style={{height: '340px', width: '100%'}}>
                                                <GoogleMapReact
                                                    center={this.state.latLng}
                                                    defaultZoom={18}
                                                    onGoogleApiLoaded={({map, maps}) => {
                                                        // Access the google.maps.Map object and customize it
                                                        this.setState({map, maps})
                                                        map.setOptions({
                                                            fullscreenControl: false, // Hide fullscreen control
                                                            draggable: false
                                                        });
                                                        this.addMarker(this.state.latLng, map, maps);
                                                    }}
                                                >


                                                </GoogleMapReact>

                                            </div>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() => {
                                                    this.props.hide();
                                                }}
                                            >
                                                Back
                                            </button>

                                            <button
                                                style={{marginTop: 30}}
                                                className="saveProductBtn"
                                                onClick={() => this.saveLocation()}
                                                disabled={this.state.loader}
                                            >
                                                {this.state.loader ? "Saving..." : "Save"}
                                                <Vortex
                                                    loading={this.state.loader}
                                                    visible={this.state.loader}
                                                    height="40"
                                                    ariaLabel="vortex-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="vortex-wrapper"
                                                    colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                                                />
                                            </button>
                                        </div>

                                        <div className="clearfix"></div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            );
        }
    }
}

export default location;
