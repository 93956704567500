export const isLoggedIn = async () => {
    try {
        var login = await getLogin();

        return login?.token;
    } catch (error) {
        console.log(error);
        throw Error(error);
    }
};

export const getLogin = async () => {
    try {
        const jsonValue = localStorage.getItem("@LoginInfo");
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        console.log(e);
    }
};

export const saveLogin = async (value) => {
    try {
        // const login = await getLogin();

        const jsonValue = JSON.stringify(value);

        await localStorage.setItem("@LoginInfo", jsonValue);
    } catch (e) {
        console.log(e);
    }
};

export const login = async (data) => {

    try {

        const result = await callApi('POST', 'v1/admin/login', data)

        console.log(result);

        return result;

    } catch (err) {
        throw err;
    }
}

export const approveChefForm = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/approved/chefform', data)
        return result;
    } catch (err) {
        throw err;
    }
}

export const approveHalallaForm = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/approved/halallaform', data)
        return result;
    } catch (err) {
        throw err;
    }
}



export const approveHalallaImageRequest = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/approved/halallaimagerequest', data)
        return result;
    } catch (err) {
        throw err;
    }
}

export const loadHalallaForms = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/halallaforms', data)
        //console.log('loadHalalaFormService---------->', result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const loadHalallaImageRequest = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/halallaimagerequest', data)
        //console.log('loadHalalaFormService---------->', result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loadForm = async (_id) => {

    try {
        console.log('loadHalalaFormService---------->', _id);
        const result = await callApi('GET', 'v1/admin/halallaform/' + _id,)
        console.log('loadHalalaFormService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loadChefForms = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/chefforms', data)
        //console.log('loadHalalaFormService---------->', result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const deleteChefForm = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/chefform', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const deleteHalallaForm = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/halallaform', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}


export const deleteHalallaRequestImage = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/halallaimagerequest', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}




export const editChefForm = async (_id) => {

    try {
        console.log('loadEditFormService---------->', _id);
        const result = await callApi('GET', 'v1/admin/chefform/' + _id,)
        console.log('loadEditFormService 1---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loadChef = async (_id) => {

    try {
        const result = await callApi('GET', 'v1/admin/chef/' + _id,)
        console.log('loadChefService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const saveChef = async (_id, obj) => {

    try {
        obj._id = _id;
        const METHOD = _id ? 'PUT' : 'POST';
        const result = await callApi(METHOD, 'v1/admin/chef', obj)
        console.log('saveChefService---------->', _id, result);
        return result;
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const saveChefForm = async (obj) => {

    try {
        const METHOD = 'PUT';
        const result = await callApi(METHOD, 'v1/admin/chefform', obj)
        return result;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const saveHalallaForm = async (obj) => {

    try {
        const METHOD = 'PUT';
        const result = await callApi(METHOD, 'v1/admin/halallaform', obj)
        return result;
    } catch (err) {
        console.log(err);
        throw err;
    }
}



export const deleteChef = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/chef', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}


export const loadHalalla = async (_id) => {

    try {
        //console.log('loadHalalaService---------->', _id);
        const result = await callApi('GET', 'v1/admin/halalla/' + _id,)
        //console.log('loadHalalaService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const saveHalalla = async (_id, obj) => {

    try {
        obj._id = _id;
        //console.log('loadHalalaService---------->', obj);
        const METHOD = _id ? 'PUT' : 'POST';
        const result = await callApi(METHOD, 'v1/admin/halalla', obj)
        //console.log('loadHalalaService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const saveBlog = async (_id, obj) => {

    try {
        obj._id = _id;
        const METHOD = _id ? 'PUT' : 'POST';
        const result = await callApi(METHOD, 'v1/admin/blog', obj)
        console.log('SaveBlogService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loadBlog = async (_id) => {

    try {
        const result = await callApi('GET', 'v1/admin/blog/' + _id,)
        console.log('loadBlogService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loadComments = async (_id) => {

    try {
        const result = await callApi('GET', 'v1/admin/blog/comments/' + _id,)
        console.log('loadBlogService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loadBlogs = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/blogs', data)
        console.log('loadBlogsService1---------->', result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const deleteBlog = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/blog', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const deleteBlogImage = async (obj) => {

    try {
        const result = await callApi('DELETE', 'v1/admin/blog/image', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const deleteComment = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/blog/comment', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}



export const loadCategories = async () => {

    try {
        const result = await callApi('GET', 'v1/categories',)
        //console.log('Categories Result Services------------------------>', result);
        return result;
    } catch (err) {
        throw err;
    }
}

export const loadHalallaLocation = async (_id) => {

    try {
        //console.log('loadHalalaServiceLoc---------->', _id);
        const result = await callApi('GET', 'v1/admin/halalla/location/' + _id)
        //console.log('Location------------------------>', result);
        return result?.location ? result.location : result.zLocation;

    } catch (err) {
        throw err;
    }
}


export const loadHalallaGallery = async (_id) => {
    try {
        const result = await callApi('GET', 'v1/admin/halalla/gallery/' + _id)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const loadChefGallery = async (_id) => {
    try {
        const result = await callApi('GET', 'v1/admin/chef/gallery/' + _id)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}


export const loadHalallasPhotos = async (_id) => {
    try {
        const result = await callApi('GET', 'v1/admin/halalla/photos/' + _id)
        return result.photos;
    } catch (err) {
        throw err;
    }
}

export const loadChefPhotos = async (_id) => {
    try {
        const result = await callApi('GET', 'v1/admin/chef/photos/' + _id)
        return result.photos;
    } catch (err) {
        throw err;
    }
}


export const loadImageBase64 = async (imagePath) => {
    try {
        const response = await callApi('GET', 'v1' + imagePath,)
        return response.base64;
    } catch (error) {
        console.log(error);
        return null;
    }

}


export const deleteGalleryImage = async (obj) => {

    try {
        const result = await callApi('DELETE', 'v1/admin/halalla/gallery', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const deleteChefGalleryImage = async (obj) => {

    try {
        const result = await callApi('DELETE', 'v1/admin/chef/gallery', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}


export const deleteHalala = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/halalla', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}


export const deletePhotosImage = async (obj) => {

    try {
        const result = await callApi('DELETE', 'v1/admin/halalla/photos', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const deleteChefPhotosImage = async (obj) => {

    try {
        const result = await callApi('DELETE', 'v1/admin/chef/photos', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const saveBlogImage = async (obj) => {

    try {
        const result = await callApi('PUT', 'v1/admin/blog/image', obj)
    } catch (err) {
        throw err;
    }
}


export const saveGalleryImage = async (obj) => {

    try {
        const result = await callApi('PUT', 'v1/admin/halalla/gallery', obj)
    } catch (err) {
        throw err;
    }
}

export const saveChefGalleryImage = async (obj) => {

    try {
        const result = await callApi('PUT', 'v1/admin/chef/gallery', obj)
    } catch (err) {
        throw err;
    }
}


export const saveGalleryPhotos = async (obj) => {

    try {
        const result = await callApi('PUT', 'v1/admin/halalla/photos', obj)
    } catch (err) {
        throw err;
    }
}

export const saveChefGalleryPhotos = async (obj) => {

    try {
        const result = await callApi('PUT', 'v1/admin/chef/photos', obj)
    } catch (err) {
        throw err;
    }
}


export const saveHalallaSchedules = async (obj) => {

    try {
        const result = await callApi('PUT', 'v1/admin/halalla/schedules', obj)
    } catch (err) {
        throw err;
    }
}


export const saveHalallaLocation = async (obj) => {
    try {
        //console.log(obj);
        const result = await callApi('PUT', 'v1/admin/halalla/location', obj)
    } catch (err) {
        throw err;
    }
}


export const loadHalallaSchedules = async (_id) => {

    try {
        const result = await callApi('GET', 'v1/admin/halalla/schedules/' + _id)
        return result.schedules && result.schedules.length > 0 ? result.schedules : result.zSchedules;
    } catch (err) {
        throw err;
    }
}


export const loadHalallaPhotos = async (_id) => {

    try {
        //console.log('loadHalala---------->', _id);
        const result = await callApi('GET', 'v1/admin/halalla/photos/' + _id, {})
        //console.log(result);
        return result;

    } catch (err) {
        throw err;
    }
}

export const loadPriceRange = async () => {

    try {
        //console.log('loadHalala---------->');
        const response = await callApi('GET', 'v1/pricerange',)
        // console.log(response);
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadStatus = async () => {

    try {
        const response = await callApi('GET', 'v1/status',)
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadHalalAuthority = async () => {

    try {
        const response = await callApi('GET', 'v1/halalauthority',)
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadHalalAuthentication = async () => {

    try {
        const response = await callApi('GET', 'v1/halalauthentication',)
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadHalalOfferings = async () => {

    try {
        const response = await callApi('GET', 'v1/halalofferings',)
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadPrayersFacilities = async () => {

    try {
        const response = await callApi('GET', 'v1/prayersfacilities',)
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadAlcoholAvailability = async () => {

    try {
        const response = await callApi('GET', 'v1/alcoholavailability',)
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadReservationAvailability = async () => {

    try {
        const response = await callApi('GET', 'v1/reservationavailability',)
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadRestroomFacilities = async () => {

    try {
        const response = await callApi('GET', 'v1/restroomfacilities',)
        return response;
    } catch (err) {
        throw err;
    }
}

export const loadInternetConnectivity = async () => {

    try {
        const response = await callApi('GET', 'v1/internetconnectivity',)
        return response;
    } catch (err) {
        throw err;
    }
}


export const loadHalallas = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/halallas', data)
        return result;
    } catch (err) {
        throw err;
    }
}

export const loadChefs = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/chefs', data)
        console.log("chefs service-----------", result);
        return result;
    } catch (err) {
        throw err;
    }
}

export const loadCustomers = async (data) => {
    try {
        const result = await callApi('POST', 'v1/admin/customers', data)
        return result;
    } catch (err) {
        throw err;
    }
}

export const resetPassword = async (data) => {
    try {
        const result = await callApi('POST', 'v1/admin/resetpassword', data)
        return result;
    } catch (err) {
        throw err;
    }
}


export const logout = async () => {
    try {
        await saveLogin({});
    } catch (error) {
        console.log(error);
        throw Error(error);
    }
};

const callApi = async (method, path, body) => {
    try {

        const url = process.env.REACT_APP_API_URL + path;

        const logininfo = await getLogin();

        console.log(method, path, body);

        const obj = {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': logininfo?.token ? logininfo.token : '',
            },
        }

        if (body) {
            obj.body = JSON.stringify(body);
        }

        console.log(obj);

        const response = await fetch(url, obj)

        console.log(response)

        if (response.status === 200) {
            const r = await response.json();
            console.log(r)
            return r.result;
        } else {
            const json = await response.json();
            throw json.err;
        }


    } catch (err) {
        throw err;
    }
}

export const savePost = async (_id, obj) => {

    try {
        obj._id = _id;
       // const METHOD = _id ? 'PUT' : 'POST';
        const result = await callApi('POST', 'v1/admin/post', obj)
        console.log('SavePostService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const deletePost = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/post', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const loadPosts = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/posts', data)
        console.log('loadPostsService1---------->', result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loadPost = async (_id) => {

    try {
        const result = await callApi('GET', 'v1/admin/post/' + _id,)
        console.log('loadPostService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const loadPostComments = async (_id) => {

    try {
        const result = await callApi('GET', 'v1/admin/post/comments/' + _id,)
        console.log('loadPostService---------->', _id, result);
        return result;

    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const deletePostComment = async (obj) => {
    try {
        const result = await callApi('DELETE', 'v1/admin/post/comment', obj)
        return result.gallery;
    } catch (err) {
        throw err;
    }
}

export const approvePost = async (data) => {

    try {
        const result = await callApi('POST', 'v1/admin/approved/post', data)
        return result;
    } catch (err) {
        throw err;
    }
}
