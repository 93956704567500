import React from "react";
import "../ResetPasswordStyle.css";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Modal,
    Form,
    Dropdown,
    InputGroup,
} from "react-bootstrap";

import * as service from "../../services/Service.js";
import { NotificationManager } from "react-notifications";

import "react-notifications/lib/notifications.css";

import {
    FaUserEdit,
    FaTrash,
    FaMapMarked,
    FaImages,
    FaBuilding,
    FaAlignCenter,
    FaUser,
    FaPhoneAlt,
    FaRegClock,
    FaLock
} from "react-icons/fa";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { properties } from "../../properties.js";
import { SLoader } from "../../components/CommonComponents";
import {resetPassword} from "../../services/Service.js";
import {Vortex} from "react-loader-spinner";
import {toast, ToastContainer} from "react-toastify";


export class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            password : '',
            newPassword : '',
            focusedInput: null,
            showPassword: false,
            showNewPassword: false,
            load: false,

        };
    }

    handleInputFocus = (inputName) => {
        this.setState({ focusedInput: inputName });
    };

    // Handle input blur events
    handleInputBlur = () => {
        this.setState({ focusedInput: null });
    };

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    toggleNewPasswordVisibility = () => {
        this.setState((prevState) => ({
            showNewPassword: !prevState.showNewPassword,
        }));
    };


    async resetPassword(){
        try {
            if(!this.state.password || !this.state.newPassword){
                console.log(1,this.state.newPassword, this.state.password)
                return (
                toast.warning("Empty Field Not Allowed", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                })
            );
            }

            if(this.state.password === this.state.newPassword){
                console.log(2,this.state.newPassword, this.state.password)
                return (
                    toast.warning("Old and New Password Are Same", {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    })
                );
            }

            this.setState({load : true})
            await service.resetPassword({
                password : this.state.password,
                newPassword : this.state.newPassword
            })
            this.setState({password : '', newPassword : '', load: false})
            toast.warning("Password Changed Successfully", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
        } catch (e) {
            console.log(e);
        }
        this.setState({loading : false, load:false})
    }

    async loadAll() {

    }


    componentDidMount() {
        this.loadAll();
    }

    componentWillUnmount() {}


    render() {
        const { focusedInput, showPassword, showNewPassword } = this.state;
        const passwordInputType = showPassword ? 'text' : 'password';
        const passwordInputTypeNew = showNewPassword ? 'text' : 'password';
        if (this.state.loading) {
            return <SLoader />;
        }else {
            var context = this;

            return (
                <div className="password-reset-container">
                    <ToastContainer />
                    <h2>Password Reset</h2>
                    <div className="password-inputs">
                        <div className="password-container">
                        <input
                            style={{ border: focusedInput === 'password' ? '2px solid #b82e3a' : '', borderRadius:6 }}
                            value={this.state.password}
                            placeholder="Password"
                            autoComplete="password"
                            type={passwordInputType}
                            name="input1" // Use the updated name
                            className="custom-input"
                            onChange={(e) => {
                                this.setState({ password: e.target.value });
                            }}
                            onFocus={() => this.handleInputFocus('password')}
                            onBlur={this.handleInputBlur}
                        />
                            <i
                                className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                id="reset-icon"
                                onClick={this.togglePasswordVisibility}
                            ></i>
                        </div>

                        <div className="password-container">
                        <input
                                style={{ border: focusedInput === 'newPassword' ? '2px solid #b82e3a' : '', borderRadius: 6 }}
                                value={this.state.newPassword}
                                autoComplete="new-password"
                                placeholder="New Password"
                                type={passwordInputTypeNew}
                                name="input1" // Use the updated name
                                className="custom-input"
                                onChange={(e) => {
                                    this.setState({ newPassword: e.target.value });
                                }}
                                onFocus={() => this.handleInputFocus('newPassword')}
                                onBlur={this.handleInputBlur}
                            />
                            <i
                                className={`fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                id="reset-icon"
                                onClick={this.toggleNewPasswordVisibility}
                            ></i>
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="gauto-theme-btn-reset"
                        onClick={() => this.resetPassword()}
                        disabled={this.state.load}
                    >
                        {this.state.load ? "Resetting.." : "Reset"}
                        <Vortex
                            loading={this.state.load}
                            visible={this.state.load}
                            height="40"
                            ariaLabel="vortex-loading"
                            wrapperStyle={{}}
                            wrapperClass="vortex-wrapper"
                            colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
                        />
                    </button>
                </div>
            );
        }
    }
}

export default ResetPassword;
