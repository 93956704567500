import React from "react";
import "../../../views/Style.css";
import {Button, Col, Modal} from "react-bootstrap";
import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {SLoader} from "../../../components/CommonComponents";
import {NotificationManager,} from "react-notifications";


export class comments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: props.blog,
            loading: false,
            loader: false,
            showDeleteModal: false,
            showApproveModal: false,
            active:true,
        };
    }


    async loadBlog() {
        try {
            this.setState({loading: true})
            const comments = await service.loadComments(this.state.blog._id);
            console.log("BlogComments---------", comments);
            this.setState({comments});
        } catch (err) {
            console.log(err)
        }
        this.setState({loading: false})
    }

    showDelete(i) {
        const comment = this.state.comments[i];
        this.setState({
            showDeleteModal: true,
            selectedCommentId: comment._id
        });
    }


    componentDidMount() {
        this.loadBlog();
    }

    renderDeleteModal() {
        return (
            <Modal
                className="modal-big modal-primary"
                show={this.state.showDeleteModal}
                onHide={() => {
                }}
            >
                <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                        <i style={{marginRight: 10}} class="fa fa-trash"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body-custom">
                    <span>Do you want to delete this chef form</span>
                </Modal.Body>
                <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={() => this.setState({
                            showDeleteModal: false,
                        })}
                        style={{marginRight: 10}}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-delete"
                        type="button"
                        variant="link"
                        onClick={async () => {
                            try {
                                await service.deleteComment({_id: this.state.selectedCommentId});
                                this.loadBlog()
                            } catch (err) {
                                console.log(err)
                            }
                            this.setState({
                                showDeleteModal: false, selectedChefId: undefined
                            })
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Modal>
        );
    }

    timeAgo(timestamp) {
        const currentDate = new Date();
        const itemDate = new Date(timestamp);
        const timeDifference = currentDate - itemDate;

        // Convert time difference to seconds
        const seconds = Math.floor(timeDifference / 1000);

        // Define time intervals
        const intervals = {
            year: 31536000,
            month: 2592000,
            day: 86400,
            hour: 3600,
            minute: 60,
            second: 1,
        };

        // Calculate time ago
        let timeAgo = 0;
        let unit = '';

        for (const [intervalName, intervalSeconds] of Object.entries(intervals)) {
            timeAgo = Math.floor(seconds / intervalSeconds);

            if (timeAgo >= 1) {
                unit = timeAgo === 1 && intervalName !== 'minute' ? intervalName : intervalName + 's';
                break;
            }
        }

        return `${timeAgo} ${unit}${timeAgo === 1 && unit !== 'minute' ? '' : 's'} ago`;

    }


    render() {
        if (this.state.loading) {
            return <SLoader/>;
        } else {
            var context = this;
            return <>
                {this.renderDeleteModal()}
                <div className="comments-container">

                    <Col md="10">
                        <div className="back-header">
                            <span
                                onClick={() =>
                                this.props.hide()
                            }>
                                Back
                            </span>
                        </div>
                    </Col>

                    <Col md="10">
                        <div className="comments-area mb-50 wow fadeInUp">
                            <div className="form-header mb-4">
                                <span>Comments - {this.state.blog.title}</span>
                            </div>
                            {this.state.comments && this.state.comments.length > 0 ? (
                                <ul className="comments-list">
                                    {this.state.comments.map((item, index) => (
                                        <li key={`Comment-Key-${item.id}`} className="comment mb-30">
                                            <div className="comment-avatar">
                                                <img
                                                    src={require('../../../assets/img/comment.png')}
                                                    alt="comment author"
                                                />
                                            </div>
                                            <div className="comment-wrap">
                                                <div className="comment-author-content">
                                                    <span className="author-name">
                                                        {item.customer?.firstName} {item.customer?.lastName}
                                                        <span className="date">
                                                            <i className="far fa-clock" /> {this.timeAgo(item.createdAt)}
                                                        </span>
                                                    </span>
                                                    <p>
                                                        {item.comment}
                                                    </p>
                                                    <div className="del">
                                                        <span className="delete" onClick={() => this.showDelete(index)}>
                                                            <i className="far fa fa-trash" /> Delete
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="no-comments-container">
                                    <img
                                        src={require('../../../assets/img/no-comment.png')}
                                        alt="No comments image"
                                        className="no-comments-image"
                                    />
                                    <p>No comments yet on this blog</p>
                                </div>
                            )}
                        </div>
                    </Col>
                </div>
            </>
        }
    }
}

export default comments;
