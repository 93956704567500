import React from "react";
import "../../../views/Style.css";
import {Button, Col, Container, Dropdown, Modal, Nav, Row,} from "react-bootstrap";
import * as service from "../../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {properties} from "../../../properties.js";
import {FaUserEdit} from "react-icons/fa";
import {NotificationManager} from "react-notifications";
import Gallery from "./gallery";

export class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            criteria: {page: 1, size: 10},
            showGallery: false,
            request: [],
            showApproveModal: false,
            showDeleteModal: false,
        };
        this.hideGallery = this.hideGallery.bind(this);

    }


    componentDidMount() {
        this.loadHalallaImageRequests(this.state.criteria)


    }

    loadPagination(criteria) {

        let paginationViews = undefined;

        if (criteria?.totalPages && criteria.totalPages > 1) {

            const subViews = [];
            let size = criteria.size;
            const count = criteria.totalPages > 10 ? (window.innerWidth < 700 ? (criteria.page + 4) : (criteria.page + 9)) : criteria.totalPages;

            for (let i = criteria.page; i <= count; i++) {

                let page = i;
                console.log('page-------------', page);

                if (page === criteria.page) {
                    subViews.push(<li className="page-item active">
                        <a className="page-link" href="#" onClick={() => {
                            this.loadHalallaImageRequests({page, size})
                        }}>
                            {page}<span className="sr-only">(current)</span>
                        </a>.
                    </li>)
                } else {
                    subViews.push(<li className="page-item">
                        <a className="page-link" href="#" onClick={() => {
                            this.loadHalallaImageRequests({page, size})
                        }}>
                            {page}<span className="sr-only">(current)</span>
                        </a>
                    </li>)
                }


            }

            paginationViews = (<div key={'key-admin-pagination-halalla-100'}
                                    style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                <nav aria-label="...">
                    <ul className="pagination">
                        <li className={'page-item ' + (criteria.page > 1 ? '' : 'disabled')}>
                            <a
                                className="page-link"
                                href="#"
                                onClick={() => {
                                    if (criteria.page > 1) {
                                        this.loadHalallaImageRequests({page: criteria.page - 1, size});
                                    }
                                }}
                            >
                                Previous
                            < /a>
                        </li>
                        {subViews}
                        <li className={'page-item ' + (criteria.page < criteria.totalPages ? '' : 'disabled')}>
                            <a className="page-link" href="#" onClick={() => {
                                if (criteria.page > 1) {
                                    this.loadHalallaImageRequests({page: criteria.page + 1, size});
                                }
                            }}>
                                Next
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>)


            console.log(paginationViews);
        }


        this.setState({paginationViews});

    }

    async loadHalallaImageRequests(criteria) {
        try {
            this.setState({loading: true});
            criteria = await service.loadHalallaImageRequest(criteria);
            const requests = criteria.requests;
            this.setState({criteria, requests});
            this.loadPagination(criteria);
        } catch (err) {
            console.log(err);
        }
        this.setState({loading: false});
    }


    showGallery(i) {
        const request = this.state.requests[i];
        this.setState({
            showGallery: true, selectedRequest: request
        });
    }

    hideGallery() {
        this.setState({
            showGallery: false, selectedRequest: undefined
        });
    }

    showApproval(i) {
        const request = this.state.requests[i];
        this.setState({
            showApproveModal: true, selectedRequestId: request._id, selectedHalallaTitle: request.halalla.title,
        });
    }

    showDelete(i) {
        const request = this.state.requests[i];
        this.setState({
            showDeleteModal: true, selectedRequestId: request._id, selectedHalallaTitle: request.halalla.title,
        });
    }


    renderDeleteModal() {
        return (<Modal
            className="modal-big modal-primary"
            show={this.state.showDeleteModal}
            onHide={() => {
            }}
        >
            <Modal.Header className="justify-content-center">
                <div className="modal-profile">
                    <i style={{marginRight: 10}} class="fa fa-trash"></i>
                </div>
            </Modal.Header>
            <Modal.Body className="modal-body-custom">
                <Modal.Body className="modal-body-custom">
                        <span>
                            Do you want to delete{' '}
                            <strong>{this.state.selectedHalallaTitle}</strong> form
                        </span>
                </Modal.Body>
            </Modal.Body>
            <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                <Button
                    className="btn-delete"
                    type="button"
                    variant="link"
                    onClick={() => this.setState({
                        showDeleteModal: false, selectedRequestId: undefined
                    })}
                    style={{marginRight: 10}}
                >
                    Cancel
                </Button>
                <Button
                    className="btn-delete"
                    type="button"
                    variant="link"
                    onClick={async () => {
                        try {
                            await service.deleteHalallaRequestImage({_id: this.state.selectedRequestId});
                            this.loadHalallaImageRequests(this.state.criteria)
                        } catch (err) {
                            console.log(err)
                        }
                        this.setState({
                            showDeleteModal: false, selectedRequestId: undefined
                        })
                    }}
                >
                    Delete
                </Button>
            </div>
        </Modal>);
    }

    renderApproveModal() {
        return (<Modal
            className="modal-big modal-primary"
            show={this.state.showApproveModal}
            onHide={() => {
            }}
        >
            <Modal.Header className="justify-content-center">
                <div className="modal-profile">
                    <i style={{marginRight: 10}} class="fa fa-check"></i>
                </div>
            </Modal.Header>
            <Modal.Body className="modal-body-custom">
                        <span>
                            Do you want to approve{' '}
                            <strong>{this.state.selectedHalallaTitle}</strong> form
                        </span>
            </Modal.Body>
            <div className="modal-footer" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                <Button
                    className="btn-delete"
                    type="button"
                    variant="link"
                    onClick={() => this.setState({
                        showApproveModal: false, selectedRequestId: undefined
                    })}
                    style={{marginRight: 10}}
                >
                    Cancel
                </Button>
                <Button
                    className="btn-delete"
                    type="button"
                    variant="link"
                    onClick={async () => {


                        try {
                            await service.approveHalallaImageRequest({_id: this.state.selectedRequestId});
                            this.loadHalallaImageRequests(this.state.criteria);
                            NotificationManager.success('Halalla Form Approved Successfully', 'Saved');
                        } catch (err) {
                            console.log(err)
                        }
                        this.setState({
                            showApproveModal: false, selectedRequestId: undefined
                        })
                    }}
                >
                    Approve
                </Button>
            </div>
        </Modal>);
    }


    render() {

        if (this.state.showGallery) {
            return <Gallery hide={this.hideGallery} request={this.state.selectedRequest}/>
        } else {
            var context = this;
            return <>
                {this.renderDeleteModal()}
                {this.renderApproveModal()}
                <Container fluid>
                    <Row
                        style={{
                            display: "flex", flexDirection: "row", marginBottom: 20,
                        }}
                    >
                        <Col>
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <label className="main-heading">
                                    Form Approval
                                </label>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <table className="custom-table">
                                <thead>
                                <tr>
                                    <th className="centered-heading">S.No</th>
                                    <th className="centered-heading">Form Title</th>
                                    <th className="centered-heading">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.requests && this.state.requests.map((item, index) => {
                                    // console.log("---LIST------", item);
                                    return (<tr key={'key-halallas-table-' + index}>
                                        <td className="centered-data">{index + 1}</td>
                                        <td className="centered-data">{item.halalla.title}</td>
                                        <td className="centered-data">
                                            <Dropdown style={{
                                                justifyContent: 'center', display: 'flex'
                                            }}>
                                                <Dropdown.Toggle
                                                    as={Nav.Link}
                                                    data-toggle="dropdown"
                                                    id="dropdown-67443507"
                                                    variant="default"
                                                    className="m-0"
                                                    style={{color: "#000"}}
                                                >
                                                    <label>
                                                        {properties.commonLabel.tableHeaderAction}
                                                    </label>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={context.showGallery.bind(context, index)}
                                                    >
                                                        <FaUserEdit
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        Gallery
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                        onClick={context.showApproval.bind(context, index)}
                                                    >
                                                        <FaUserEdit
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        Approve
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                        onClick={context.showDelete.bind(context, index)}
                                                    >
                                                        <FaUserEdit
                                                            size={20}
                                                            style={{
                                                                marginRight: 5, marginBottom: 5,
                                                            }}
                                                        />
                                                        Delete
                                                    </Dropdown.Item>

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>);
                                })}
                                </tbody>
                            </table>

                            {this.state.paginationViews}


                        </Col>
                    </Row>
                </Container>
            </>
        }
    }
}

export default Index;
