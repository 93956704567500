import React from "react";
import "../../views/Style.css";
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Modal,
    Form,
    Dropdown,
    InputGroup,
} from "react-bootstrap";

import * as service from "../../services/Service.js";
import "react-notifications/lib/notifications.css";
import {
    FaUserEdit,
    FaTrash,
    FaMapMarked,
    FaImages,
    FaBuilding,
    FaAlignCenter,
    FaUser,
    FaPhoneAlt,
    FaRegClock,
    FaLock
} from "react-icons/fa";
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import {properties} from "../../properties.js";
import {SLoader} from "../../components/CommonComponents";
import {WithContext as ReactTags} from 'react-tag-input';
import {loadCustomers} from "../../services/Service.js";
import Editor from "./Editor";


export class Customer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showAddEdit: false,
            halallas: [],

        };
    }

    componentDidMount() {
        this.loadCustomers()

    }

    async loadCustomers() {
        try {
            this.setState({loading: true});
            const response = await service.loadCustomers({});
            const customers = response.customers; // Extract the array from the response
            console.log("customers----------------", customers);
            this.setState({customers});
        } catch (err) {
            console.log(err);
        }
        this.setState({loading: false});
    }

    showEdit(i) {
        const customer = this.state.customers[i];
        console.log("---Edit Customer---", customer);
        this.setState({
            customerId: customer.id,
            showAddEdit: true,
            customerEmail: customer.email,
            customerFirstName: customer.firstName,
            customerLastName: customer.lastName,
            customerActivated: customer.activated,
        });
    }


    renderAddEdit() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h4">
                                        Customer Details
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: 20,
                                        }}
                                    >
                                        <Checkbox
                                            onChange={(activated, event) => {
                                                this.setState({customerActivated: activated});
                                            }}
                                            checked={this.state.customerActivated}
                                            value={this.state.customerActivated}
                                            icon={
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flex: 1,
                                                        backgroundColor: "#29427d",
                                                        alignSelf: "stretch",
                                                    }}
                                                >
                                                    <Icon.FiCheck color="#b82e3a" size={20}/>
                                                </div>
                                            }
                                            borderColor="gray"
                                            // borderWidth={0}
                                            borderRadius={20}
                                            style={{overflow: "hidden"}}
                                            size={25}
                                            label="Active"
                                        />
                                    </div>

                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <button
                                            style={{marginTop: 30}}
                                            className="saveProductBtn"
                                            onClick={() =>
                                                this.setState({
                                                    showAddEdit: false,
                                                    agencyId: undefined,
                                                    name: undefined,
                                                    email: undefined,
                                                    description: undefined,
                                                    contactPersonName: undefined,
                                                    contactPersonPhone: undefined,
                                                    activated: undefined,
                                                    path: undefined,
                                                    newPassword: undefined,
                                                })
                                            }
                                        >
                                            {properties.commonLabel.cancelBtnLabel}
                                        </button>

                                        <button
                                            style={{marginTop: 30}}
                                            className="saveProductBtn"
                                            onClick={() => this.saveProduct()}
                                        >
                                            {" "}
                                            {properties.commonLabel.saveBtnLabel}
                                        </button>
                                    </div>

                                    <div className="clearfix"></div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    render() {

        if (this.state.loading) {
            return <SLoader/>;
        } else if (this.state.showAddEdit) {
            return this.renderAddEdit();
        } else {
            var context = this;
            return <>
                <Container fluid>
                    <Row
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: 20,
                        }}
                    >
                        <Col>
                            <div style={{display: "flex", justifyContent: "left"}}>
                                <label className="main-heading">
                                    Customers
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            {/*<Card className="strpied-tabled-with-hover">
                            <Card.Header></Card.Header>

                            <table
                                id="mytable"
                                className="table table-bordred table-striped"
                            >
                                <thead>
                                <tr>
                                    <th className="border-0">
                                        Title
                                    </th>
                                    <th className="border-0">
                                        Address
                                    </th>
                                    <th className="border-0">
                                        {properties.commonLabel.tableHeaderAction}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.halallas &&
                                    this.state.halallas.map( (item, index)  => {
                                        console.log("---LIST------", item);
                                        return (
                                            <tr key={index}>
                                                <td>{item.title}</td>
                                                <td>{item.location?.address}</td>
                                                <td>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            as={Nav.Link}
                                                            data-toggle="dropdown"
                                                            id="dropdown-67443507"
                                                            variant="default"
                                                            className="m-0"
                                                            style={{color: "#000"}}
                                                        >
                                                            <label
                                                                style={{color: "#000", marginLeft: -15}}
                                                            >
                                                                {properties.commonLabel.tableHeaderAction}
                                                            </label>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item

                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                {properties.commonLabel.actionEdit}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item

                                                            >
                                                                <FaTrash
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                {properties.commonLabel.actionDelete}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item

                                                            >
                                                                <FaImages
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                {properties.commonLabel.actionGallery}
                                                            </Dropdown.Item>
                                                            <Dropdown.Item

                                                            >
                                                                <FaImages
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                {properties.commonLabel.actionAttributes}
                                                            </Dropdown.Item>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Card>*/}

                            <table className="custom-table">
                                <thead>
                                <tr>
                                    <th className="centered-heading">S.No</th>
                                    <th className="centered-heading">First Name</th>
                                    <th className="centered-heading">Last Name</th>
                                    <th className="centered-heading">Email</th>
                                    <th className="centered-heading">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.customers &&
                                    this.state.customers.map((item, index) => {
                                        console.log("---LIST------", item);
                                        return (
                                            <tr>
                                                <td className="centered-data">{index + 1}</td>
                                                <td className="centered-data">{item.firstName}</td>
                                                <td className="centered-data">{item.lastName}</td>
                                                <td className="centered-data">{item.email}</td>
                                                <td className="centered-data">
                                                    <Dropdown style={{
                                                        justifyContent:'center',
                                                        display:'flex'
                                                    }}>
                                                        <Dropdown.Toggle
                                                            as={Nav.Link}
                                                            data-toggle="dropdown"
                                                            id="dropdown-67443507"
                                                            variant="default"
                                                            className="m-0"
                                                            style={{color: "#000"}}
                                                        >
                                                            <label>
                                                                {properties.commonLabel.tableHeaderAction}
                                                            </label>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item
                                                                onClick={context.showEdit.bind(
                                                                    context,
                                                                    index
                                                                )}
                                                            >
                                                                <FaUserEdit
                                                                    size={20}
                                                                    style={{
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                    }}
                                                                />
                                                                {properties.commonLabel.actionEdit}
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {false && <div style={{display: "flex", justifyContent: "center"}}>
                                <nav aria-label="...">
                                    <ul className="pagination">
                                        <li className="page-item disabled">
                                            <a
                                                className="page-link"
                                                href="/script:;"
                                                //tabindex="-1"
                                            >
                                                Previous
                                            </a>
                                        </li>
                                        <li className="page-item active">
                                            <a className="page-link" href="/;">
                                                1 <span className="sr-only">(current)</span>
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="/;">
                                                2
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="/;">
                                                3
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="/;">
                                                Next
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>}
                        </Col>
                    </Row>
                </Container></>
        }
    }
}

export default Customer;
